@import "src/app/styles/index";

.wrapper {
  @include box-size(417px, 100%);
  @include flex-column(flex-start, center);
  max-height: calc(100vh - 22px);
  max-width: 1200px;

  .list {
    @include flex-column(flex-start, center);
    @include box-size(96%, 100%);
    gap: 12px;
    padding: 20px;
    overflow-y: auto;

    .item {
      @include flex(space-between, flex-start);
      @include box-size(calc(100% - 16px), auto);
      padding: 8px;
      flex: 1 0 auto;
      min-height: 34px;
      background-color: var(--bg-primary);
      border-radius: 12px;

      .members {
        @include flex(flex-start);
        @include box-size(90%, auto);
        min-width: 80%;
        gap: 8px;
        flex-wrap: wrap;


        .member {
          @include flex(flex-start);
          @include box-size(auto, 30px);
          gap: 6px;
          padding: 4px;
          color: var(--text-primary);
          box-shadow:  var(--box-shadow);
          border-radius: 12px;
        }
      }
    }
  }
}