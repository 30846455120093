@import "src/app/styles";

.wrapper {
  @include box-size(100%, 100vh);
  @include flex-column;
  gap: 18px;
  background-color: #6C5896;

  .logo {
    z-index: 10;
    position: relative;

    .star{
      z-index: -1;
      position: absolute;
      bottom: -84px;
      right: -210px;
    }
  }

  .icon {
    position: fixed;
    bottom: 20px;
    right: 0;
  }

  .modal {
    z-index: 10;
    @include flex-column;
    gap: 20px;
    padding: 18px;
    @include box-size(calc(374px - 36px), auto);
    border-radius: 20px;
    background-color: var(--bg-secondary);



    .titles {
      @include flex-column;
      gap: 8px;
    }

    .btns {
      @include flex;
      gap: 12px;
      width: 100%;
    }

  }
}