@import "src/app/styles";

.wrapper {
  @include flex(flex-end, flex-end);
  align-self: flex-end;
  flex: 1;
  gap: 4px;
  height: 100%;
  min-width: 21px;
  border-radius: 12px;

  .edited {
    width: 70px;
  }

  .icon {
    width: 24px;
    max-height: 24px;


    svg {
      fill: var(--text-action);
    }
  }

  .date {
    margin: 0 8px;
  }
}

