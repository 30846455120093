:root {
  --status-inoffice: #29CC39;
  --status-homework: #83F;
  --status-businesstrip: #33BFFF;
  --status-vacation: #2EE5C9;
  --status-sickleave: #FFCB33;
  --status-meeting: #F63;
  --status-notavailable: #E62E7B;
  //----------------------------
  --green: #10C44C;
  --white: #FFFFFF;
  //----------------------------
  --transition: .5s;
}