@import "src/app/styles";

.wrapper {
  @include flex(flex-end, flex-end);
  color: var(--text-primary);
  max-width: 290px;
  background-color: var(--bg-secondary);
  padding: 0 8px;
  border-radius: 12px;
  -moz-user-select: text;
  -webkit-user-select: text;

  .audio{
    @include flex;
    width: 200px;
  }
}


