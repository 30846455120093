@import "../../../../app/styles/index";

.wrapper {
  @include box-size(416px, auto);
  @include flex-column();
  padding: 18px;
  gap: 12px;

  .user{
    @include flex-column();
    gap: 12px;
  }

  .btns {
    @include flex;
    margin-top: 20px;
    gap: 8px;
    width: 80%;
  }
}