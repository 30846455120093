@import "src/app/styles";

.wrapper {
  @include box-size(100%, auto);
  .header {

    @include flex(space-between);
    @include box-size(100%, 40px);
    cursor: pointer;
    transition: background-color var(--transition);

    .title {
      width: 96%;
      display: flex;
      align-items: center;
      gap: 8px;

      .caption{
        @include flex-column;
        width: 0;
        min-width: 100%;
      }
    }

    .arrow {
      @include flex;
      width: 30px;
      height: 30px;
    }
  }

}
