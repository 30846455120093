@import "src/app/styles/index";


.wrapper {
  @include box-size(calc(343px - 32px), auto);
  @include flex-column(flex-start);
  overflow-y: auto;
  padding: 30px 16px;

  .header{
    margin-bottom: 8px;
  }

  .btns {
    @include flex-column;
    width: 100%;
    gap: 16px;
    margin-top: 28px;
  }

}