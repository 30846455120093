@import "src/app/styles";

.wrapper {
  @include box-size(auto, 28px);
  @include flex;
  gap: 4px;

  .step {
    @include flex;
    gap: 4px;

    .item {
      @include flex;
      @include box-size(28px, 28px);
      color: var(--text-action);
      border-radius: 50%;
      border: 1px solid var(--text-action);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      transition: all 0.5s;

      &_active {
        background-color: var(--text-action);
        color: var(--text-primary);
      }
    }
  }
}

