@import "src/app/styles/index";

.wrapper {
  @include box-size(calc(100% - 44px), calc(100% - 44px));
  @include flex-column(flex-start, flex-start);
  gap: 12px;
  border-radius: 12px;
  padding: 22px;

  position: relative;

  .container {
    @include box-size(100%, auto);
    @include flex-column(flex-start, flex-start);
    background-color: var(--bg-secondary);
    margin-top: 30px;
    border-radius: 18px;
    overflow-y: auto;

    .avatar {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 10px 10px 50% 50%;
      border: 20px solid var(--bg-primary);
      background-color: var(--bg-primary);

      @media screen and (max-width: 564px) {
        top: 16px;
        left: 16px;
        border: 14px solid var(--bg-primary);
      }
    }

    .btns {
      @include flex(flex-start, flex-start);
      flex-wrap: wrap;
      position: absolute;
      top: 26px;
      left: 228px;
      gap: 12px;
      background-color: var(--bg-primary);
      border-radius: 0 0 18px 18px;
      border: 14px solid var(--bg-primary);

      svg {
        fill: var(--text-action);
      }

      @media screen and (max-width: 564px) {
        top: 20px;
        left: 132px;
        border: 14px solid var(--bg-primary);
      }
    }

    .description {
      @include flex-column(flex-start, flex-start);
      width: 0;
      gap: 6px;
      margin-top: 180px;
      min-width: 90%;
      padding: 24px;
      flex: 1;

      @media screen and (max-width: 564px) {
        margin-top: 80px;
      }

      .name {
        @include flex(flex-start);
        width: 100%;
        gap: 6px;
      }

      .networkStatus {
        width: 100%;
      }

      .info {
        margin-top: 12px;
        width: 0;
        min-width: 100%;
        flex: 1;
      }

    }
  }


}