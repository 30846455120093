@import "src/app/styles/index";

.wrapper {
  @include flex;
  @include box-size(calc(400px - 36px), 150px);
  background-color: var(--bg-primary);
  border-radius: 12px;
  max-width: 375px;
  min-width: 288px;
  padding: 0 18px;
  position: relative;
  cursor: pointer;
  min-height: 150px;

  @include width-to(sm) {
    width: auto;
    padding: 0
  }

  .arrow{
    position: absolute;
    top: 26px;
    right: 24px;
    svg{
      fill: var(--text-primary);
    }
  }

  .body {
    @include flex-column(center, flex-start);
    @include box-size(calc(100% - 20px), calc(100% - 20px));
    gap: 12px;

    .position{
      margin-left: 56px;
      margin-top: -12px;
    }
  }
}

.company {
  @include flex(space-between);
  @include box-size(calc(408px - 32px), 32px);
  gap: 12px;
  padding: 8px 16px;
  background-color: var(--bg-primary);
  border-radius: 12px;
  cursor: pointer;

  .container {
    @include flex(flex-start);
    gap: 16px;

    .name {
      @include flex;
      max-width: 200px;
    }

    .dot {
      @include box-size(4px, 4px);
      min-width: 4px;
      border-radius: 50%;
      background-color: var(--text-primary);
    }
  }

  svg {
    fill: var(--control-primary)
  }
}