@import "src/app/styles/index";

.wrapper {
  @include box-size(calc(541px - 40px), 100%);
  @include flex-column(flex-start);
  max-width: 541px;
  gap: 8px;
  background-color: var(--bg-primary);
  border-radius: 20px;
  padding: 0 20px 24px 20px;

  @include width-to(sm){
    @include box-size(calc(99vw - 40px) , 100%);
    padding: 20px 20px 24px 20px;
  }

  .header {
    width: 100%;
    margin-top: 35px;
    position: relative;
    @include flex(flex-end);

    .avatar {
      position: absolute;
      top: -24px;
      left: -14px;
      border: 14px solid var(--bg-primary);
      border-radius: 10px 10px 50% 50%;
    }

    .btns {
      @include box-size(calc(100% - 158px), 61px);
      @include flex(flex-end);
      gap: 8px;

      svg {
        fill: var(--text-action);
      }
    }

  }

  .mainInfo {
    @include flex-column(flex-start,flex-start);
    padding: 74px 20px 20px 20px;
    width: calc(100% - 40px);
    background-color: var(--bg-secondary);
    border-radius: 12px;
    margin-bottom: 12px;

    .name {
      @include flex(flex-start);
      //width: 100%;
      margin: 10px 0;
      gap: 8px;
    }

    .border{
      margin: 12px 0;
      @include box-size(100%, 1px);
      background-color: var(--border-secondary);
    }
  }


  .companyCard {
    @include flex;
    @include box-size(84%, auto);
  }
}