@import "src/app/styles/index";

.wrapper {
  @include box-size(calc(416px - 48px), calc(100% - 48px));
  @include flex-column(flex-start, flex-start);
  gap: 16px;
  margin: 24px;

  .header {
    @include flex-column();
    width: 100%;
    gap: 4px;
    color: var(--text-primary);
  }

  .list {
    @include flex-column(flex-start, flex-start);
    width: 100%;
    gap: 6px;
    height: 100%;
    min-height: 60px;
    overflow-y: auto;
    max-height: 68vh;

    .item {
      width: 100%;
      @include flex(space-between);

      .children {
        width: 80%;
        @include flex(flex-start);
      }

      .deleteIcon {
        width: 20%;

        svg {
          fill: var(--text-primary);
        }
      }
    }


  }

  .footer {
    @include flex(space-between);
    width: 100%;

    .confirm {
      width: 50%;
      @include flex(space-between);
      gap: 12px;
    }
  }
}