@import "src/app/styles/index";

.wrapper {
  @include box-size(calc(416px - 48px), 100%);
  @include flex-column(flex-start);
  max-height: calc(90vh - 60px);
  gap: 20px;
  overflow-y: auto;
  padding: 30px 24px;
  padding-bottom: 52px;

  .mainInfo{
    @include flex-column();
    gap: 15px;
    margin-bottom: 18px;
  }

  .successRegister{
    height: 38px;
  }

  .rows {
    @include flex-column();
    @include box-size(100%, auto);
    gap: 8px;
    border-radius: 12px;

    .border{
      @include box-size(100%, 1px);
      background: var(--border-secondary);

    }

    .item_company {
      flex-direction: row !important;
      align-items: center !important;
      gap: 12px !important;
    }

    .item, .item_company {
      @include box-size(100%, auto);
      min-height: 50px;
      @include flex-column(center, flex-start);
      gap: 6px;
    }
  }

  .pushSwitch{
    width: 100%;
    @include flex(space-between);
  }

  .deleteAcc {
    @include flex(flex-start);
    width: 100%;
    margin-top: 20px;
    cursor: pointer;
  }
}