@import "src/app/styles";

.wrapper {
  @include flex-column(flex-start, flex-start);
  gap: 4px;
  width: 100%;
  cursor: pointer;
  margin-top: 12px;

  .link {
    word-break: break-all;
    cursor: pointer;
    color: var(--text-action);
  }

  .info {
    @include flex(space-between);
    width: calc(100% - 8px);
    flex-wrap: wrap;
    //gap: 12px;
    padding-left: 8px;
    border-left: 2px solid var(--border-primary);


    .description {
      @include flex-column(flex-start, flex-start);
      align-self: flex-start;
      //max-width: 70%;
      flex: 1;
      width: 0;
    }

    .img {
      max-width: 100px;
      max-height: 100px;
      border-radius: 12px;
      overflow: hidden;
    }
  }
}