@import "src/app/styles";

.wrapper {
  @include box-size(auto, 40px);
  @include flex;
  padding: 0 25px;
  gap: 16px;
  border-radius: 24px;
  background: var(--white);

  .title{
    font-weight: 600;
    cursor: pointer;
  }

  .container {
    @include flex;
    gap: 12px;

    .item {
      @include flex;
      cursor: pointer;
      font-weight: 600;

      .colorPiker {
        @include box-size(24px, 24px);
        position: relative;
        border-radius: 50%;
        margin: 0 2px;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          pointer-events: none;
        }

        &_input {
          @include box-size(100%, 100%);
          -webkit-appearance: none;
          border: none;
          opacity: 0;
          border-radius: 50%;
        }
      }

      .tool {
        position: relative;
        width: 20px;

        .tools {
          @include flex-column;
          z-index: 100;
          background: var(--white);
          gap: 4px;
          border-radius: 16px;
          padding: 8px 4px;
          position: absolute;
          bottom: 40px;
          left: -4px;
        }
      }

      .widthSlider {
        @include flex-column;
        position: relative;
        width: 18px;

        .slider{
          @include flex-column(flex-start);
          @include box-size(28px, 102px);
          border-radius: 16px;
          gap: 4px;
          padding: 8px 4px;
          background: var(--white);
          position: absolute;
          z-index: 100;
          bottom: 40px;
          left: -8px;

          .sliderWidth{
            height: 84px;
          }
        }

        .icon{
          @include flex-column;

          .dot {
            @include flex;
            @include box-size(8px, 8px);
            background-color: var(--text-action);
            border-radius: 50%;
          }

          .value {

          }
        }

      }

    }
  }


}
