@import "src/app/styles";

.wrapper {
  @include flex-column();
  @include box-size(100%, auto);
  gap: 14px;
  border-radius: 12px;
  background-color: var(--bg-secondary);

  .item {
    @include box-size(100%, auto);
    min-height: 50px;
    @include flex-column(center, flex-start);
    gap: 6px;
    border-bottom: 1px solid var(--border-secondary);
  }
}
