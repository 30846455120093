.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    animation: rotate 2s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .path {
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }

    .path {
      stroke: var(--control-secondary);
    }

  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: var(--control-secondary);
  }
}


.primary {
  .path {
    stroke: var(--control-primary) !important;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}