@import "src/app/styles";

.wrapper {
  @include flex;
  position: relative;
  width: 30px;

  .microIcon{
    z-index: 1000;
  }

  .menu {
    @include flex();
    @include box-size(auto, auto);
    z-index: 100;
    position: absolute;
    bottom: 45px;
    right: -4px;
    cursor: default;

    .lock {
      @include flex;
      @include box-size(100vw, 100vh);
      position: fixed;
      bottom: 0;
      left: 0;

      .icon {
        pointer-events: none;
        @include flex;
        position: absolute;
        bottom: 130px;
        right: 12px;
        transition: all 0.5s;
      }
    }

    .audioControl {
      @include flex-column;
      @include box-size(40px, auto);
      padding: 4px 0;
      gap: 8px;
      background-color: var(--bg-quaternary);
      border-radius: 12px;
      box-shadow: var(--box-shadow);

      .delete {
        svg {
          fill: var(--text-negative-pressed);
        }
      }

      div {
        cursor: pointer;

        svg {
          fill: var(--text-primary);
        }
      }
    }
  }
}


.startRecording:before {
  pointer-events: none;
  content: "";
  border: 2px solid var(--text-negative-pressed);
  border-radius: 50%;
  position: absolute;
  animation: pulsateMicrophone 1.6s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.0;
  z-index: 99;
  height: 40px;
  width: 40px;
}

@keyframes pulsateMicrophone {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
}