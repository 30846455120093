@import "src/app/styles";

.wrapper {
  @include box-size(100%, 100%);
  @include flex-column(flex-start);
  border-radius: var(--border-radius);
  overflow-x: hidden;

  .search {
    @include box-size(calc(100% - 32px), 48px);
    @include flex;
    padding: 0 16px;
    margin-bottom: 12px;
  }

  .tabs {
    @include box-size(calc(100% - 32px), 48px);
    @include flex(flex-start);
    margin: 0 16px;
  }


  .list {
    @include flex-column(flex-start, flex-start);
    @include box-size(100%, auto);
    flex: 1;
    overflow-y: auto;
    padding: 8px 0;
  }


}
