@import "src/app/styles";

.wrapper {
  @include flex;
  @include box-size(100%, 32px);
  gap: 12px;
  position: relative;

  .item {
    height: 100%;
    color: var(--text-secondary);
    position: relative;
    cursor: pointer;
    font-weight: 500;

    &_active {
      color: var(--text-action);
    }
  }

  .border{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border: 1px solid var(--border-secondary);
  }

  .line {
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    background-color: var(--text-action);
    border-radius: 12px;
  }
}
