@import "src/app/styles";

.wrapper {
  @include box-size(calc(100% - 42px), auto);
  @include flex-column;
  align-items: flex-start;
  gap: 24px;
  //max-width: 550px;
  margin-left: 48px;
  margin-top: 30px;
  margin-bottom: 40px;

  @include width-to(md){
    width: calc(100% - 4px);
    margin-left: 4px;
  }

  .back {
    @include flex(flex-start);
    gap: 12px;
    cursor: pointer;

    svg {
      fill: var(--text-action);
    }
  }

  .item {
    @include flex-column(flex-start, flex-start);
    margin-left: 18px;
    height: auto;
    gap: 12px;
    width: calc(100% - 18px);
    flex: 1;
    max-width: 603px;
  }
}