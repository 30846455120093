@import "src/app/styles";

.wrapper {
  position: fixed;
  bottom: 12px;
  left: 12px;
  z-index: 100;

  @include flex;

}


