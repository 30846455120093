@import "src/app/styles";

.wrapper {
  @include box-size(376px,auto);
  @include flex-column(flex-start);
  gap: 12px;

  .description{
    @include flex-column;
    gap: 12px;

    .title{
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      color: var(--text-primary);
      line-height: 40px;
    }
    .subtitle{
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      text-align: center;
      color: var(--text-secondary);
      width: 230px;
      line-height: 24px;
    }
  }

  .input{
    width: 100%;
  }

  .help{
    color: var(--text-secondary);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 18px;
  }
}