@import "src/app/styles";

.wrapper {
  @include flex(flex-end, flex-end);
  color: var(--text-primary);
  max-width: 290px;
  padding: 4px 8px;
  border-radius: 12px;
  -moz-user-select: text;
  -webkit-user-select: text;

  .audios{
    @include flex-column;
    gap: 2px;
    .audio{
      @include flex;
      max-width: 200px;
    }
  }
}


