
html {
  scroll-behavior: smooth;
  font-family: 'Inter', sans-serif;
  overflow: hidden;
  -moz-user-select: none;
  -webkit-user-select: none ;
  background: var(--bg-html);

   button {
    all: unset;
    cursor: pointer;
  }

  //a {
  //  color: var(--main-active);
  //}

  //scrollbar

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--control-scroll-bg);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--control-scroll);
    border-radius: 20px;

  }

  border-style: none;


  li {
    list-style-type: none;
  }

}


.inner-seek-block, .connect, .buffered {
  background-color: var(--text-action) !important;
}

.thumb {
  .handler {
    background-color: var(--text-action) !important;
  }
}


.react-switch-bg {

  div {
    display: flex;
    align-items: center;

    &:nth-child(1) {
      margin-left: 1px;
      justify-content: flex-start;
    }

    &:nth-child(2) {
      margin-right: 1px;
      justify-content: flex-end;
    }

    .styles_wrapper__x-ey7 {
      position: relative;
      top: 10px;
    }
  }


}

.cropper-modal{
  background-color: inherit;
}