@import "src/app/styles";

.wrapper {
  @include box-size(100%, auto);
  @include flex;
  gap: 2px;
  flex-wrap: wrap;
  color: var(--text-primary);
  max-width: 600px;
  cursor: pointer;
  position: relative;

  .mask{
    position: absolute;
    @include box-size(100%, 100%);
    border-radius: 12px;
    top: 0;
    left: 0;
    background: var(--bg-dimming);
    z-index: 10;
  }

  .info{
    position: absolute;
    bottom: 4px;
    right: 12px;
    border-radius: 12px;
    pointer-events: none;
  }
}

