@import "src/app/styles/index";

.wrapper {
  @include box-size(calc(100% - 80px), 100%);
  @include flex(flex-start, flex-start);
  gap: 30px;
  background-color: var(--bg-secondary);
  border-radius: 12px;
  overflow-y: auto;
  flex-wrap: wrap;
  padding: 40px;

  @include width-to(min){
    padding: 40px 12px;
    width: calc(100% - 24px);
  }

  .avatarBlock {
    @include flex-column(flex-start, flex-start);
    gap: 20px;

    .name {
      @include flex(flex-start);
      flex: 1;
      gap: 6px;
    }

    .btns {
      @include flex(center, flex-start);
      @include box-size(100%, auto);
      gap: 12px;

      svg {
        fill: var(--text-fixed);
      }
    }
  }

  .description {
    max-width: 400px;
    width: 100%;
    @include flex-column(flex-start, flex-start, 12);
  }
}