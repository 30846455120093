@import "src/app/styles/index";

.wrapper {
  @include flex;
  max-height: 18px;
  background-color: var(--bg-quaternary);
  border-radius: 12px;
  padding: 0 8px;

  div {
    color: var(--text-fixed) !important;
  }
}