@import "src/app/styles/index";

.wrapper {
  @include box-size(calc(549px - 40px), 100%);
  @include flex-column(flex-start);
  max-width: 549px;
  gap: 20px;
  overflow-y: auto;
  padding: 20px;
  background-color: var(--bg-primary);
  border-radius: 20px;

  .mainInfo {
    @include flex-column();
    width: 100%;
    gap: 4px;


    .name {
      @include flex;
      margin-top: 16px;
      max-width: 90%;
      gap: 8px;
    }
  }

  .secondaryInfo {
    @include flex-column;
    @include box-size(calc(501px - 40px), auto);
    gap: 12px;
    flex: 1;
    background-color: var(--bg-secondary);
    border-radius: 12px;
    padding: 20px;

    .row {
      @include flex-column;
      @include box-size(100%, auto);

    }

    .border {
      @include box-size(100%, 1px);
      background-color: var(--border-secondary);
    }
  }
}