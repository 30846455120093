@import "src/app/styles";

.wrapper {
  @include box-size(auto, 100%);
  @include flex-column;
  flex: 1;

  .header {
    @include flex;
    @include box-size(100%, 70px);
    max-height: 70px;
    background-color: var(--bg-secondary);
  }

  .messageList {
    @include flex;
    width: 100%;
    height: 0;
    flex: 1 1 auto;
    position: relative;
  }

  .input {
    @include flex(center, flex-start);
    @include box-size(100%, auto);
    min-height: 56px;
    background-color: var(--bg-secondary);
  }
}
