:root[data-theme="light"] {
  --text-primary: #2A313C;
  --text-secondary: #717394;
  --text-inactive: #A5ABD7;
  --text-fixed: #FFFFFF;
  --text-action: #7B57C8;
  --text-action-pressed: #6F4BBC;
  --text-positive: #10C44C;
  --text-negative: #FF4B4B;
  --text-negative-pressed: #E74343;
  //------------------------------------
  --bg-html:  #f6f6f7;
  --bg-primary: #EFF2F7;
  --bg-secondary: #FFFFFF;
  --bg-tertiary: #C3C4E4;
  --bg-quaternary: #A5ABD7;
  --bg-dialogue: #D3DAE7;
  --bg-dimming: rgba(214, 199, 227, 0.5);
  --bg-chat-primary:#EFF2F7;
  --bg-chat-secondary:#FFFFFF;
  //------------------------------------
  --control-primary: #7B57C8;
  --control-primary-pressed: #6F4BBC;
  --control-secondary: #DDE4EF;
  --control-secondary-disabled: #C3C4E4;
  --control-tertiary: #A5ABD7;
  --control-tertiary-active: #7B57C8;
  --control-negative: #FF4B4B;
  --control-negative-pressed: #E74343;
  --control-scroll: #C3C4E4;
  --control-scroll-bg: #E5F0F7;
  //------------------------------------
  --border-primary: #7B57C8;
  --border-primary-pressed:#6F4BBC;
  --border-secondary: #D6E1F2;
  --border-negative: #FF4B4B;
  --border-negative-pressed: #E74343;
  //------------------------------------
  --box-shadow: 0 0 6px 0 rgba(0, 1, 2, 0.32);
  --box-shadow-active: 0 0 3px 0 rgba(0, 1, 2, 0.32);
  //------------------------------------
  input {
    color-scheme: light;
  }
}
