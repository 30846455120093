@mixin box-size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin flex($justify: center,  $align: center, $gap: 0) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: row;
  gap: $gap * 1px;
}

@mixin flex-column($justify: center,  $align: center, $gap: 0) {
  @include flex($justify, $align, $gap);
  flex-direction: column;
}

@mixin position($position: absolute, $top: 0, $left: 0) {
  position: $position;
  top: $top;
  left: $left;
}

@mixin scrollBarVertical() {
  &::-webkit-scrollbar {
    width: 4px;
  }
}
@mixin textSelect() {
  -moz-user-select: auto!important;
  -khtml-user-select: auto!important;
  -webkit-user-select: auto !important;
}


