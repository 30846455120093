@import "src/app/styles";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 16px);
  max-width: 460px;
  flex-wrap: wrap;
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  padding: 4px 8px;
  border-radius: 12px;
  -moz-user-select: text;
  -webkit-user-select: text;

  .text {
    color: var(--text-primary) !important;
    width: 100%;

    a {
      color: var(--text-action) !important;
    }

    .emojiWrapper {
      color: transparent !important;
      position: relative;
      pointer-events: none;

      .emoji {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .info {
      float: right;
      margin-top: 4px;
      margin-left: 4px;
    }
  }

  .row {
    .longWord {
      word-break: break-all;
    }

    .url {
      @include flex-column(flex-start, flex-start);
      word-break: break-all;
    }
  }


  a {
    color: var(--text-action) !important;
  }

  .tag {
    color: var(--text-action) !important;
    cursor: pointer;
  }


}

