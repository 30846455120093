@import "src/app/styles";

.wrapper {
  @include flex-column(flex-start, flex-start);
  @include box-size(100%, 100%);
  gap: 12px;


  .body {
    width: 100%;
    @include flex-column(flex-start, flex-start);
    gap: 22px;

    .border{
      width: 100%;
      height: 1px;
      background-color: var(--control-secondary);
    }

    .item {
      width: 100%;
      @include flex(space-between);

      svg {
        fill: var(--text-inactive);
      }

      &_title {
        font-weight: 400;
        font-size: 12px;
        color: var(--text-primary);
      }

      &_value {
        width: 40%;
        @include flex(flex-start);
        font-size: 12px;
      }


      //svg {
      //  fill: var(--text-primary);
      //}

    }
  }
}




