@import "src/app/styles";

.wrapper {
  @include box-size(376px, auto);
  @include flex-column(flex-start);
  gap: 12px;


  .description {
    @include flex-column;
    gap: 12px;

    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      color: var(--text-primary);
      line-height: 40px;
    }

    .subtitle {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      text-align: center;
      color: var(--text-secondary);
      width: 260px;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }

  .input {
    width: 100%;
  }

  .inputLastName {
    width: 100%;
    margin-bottom: 20px;
  }
}