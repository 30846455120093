@import "src/app/styles";

.wrapper {
  @include box-size(100%, 46px);
  @include flex(flex-start);
  gap: 8px;
  color: var(--text-1);

  .item{
cursor: pointer;
  }
}




