$breakpoints: (
        min: 455px,
        sm: 680px,
        md: 800px,
        lg: 1024px,
        xl: 1400px,
);

@mixin width-from($breakpoint) {
  $size: map-get($breakpoints, $breakpoint);
  @if ($size == 0) {
    @content;
  } @else {
    @media screen and (min-width: $size) {
      @content;
    }
  }
}

@mixin width-to($breakpoint) {
  $size: map-get($breakpoints, $breakpoint);
  @if ($size == 0) {
    @content;
  } @else {
    @media screen and (max-width: $size) {
      @content;
    }
  }
}

@mixin width-from-to($from, $to) {
  $min: map-get($breakpoints, $from);
  $max: map-get($breakpoints, $to) - 1px;

  @if ($min == 0) {
    @media screen and (max-width: $max) {
      @content;
    }
  } @else {
    @media screen and (min-width: $min) and (max-width: $max) {
      @content;
    }
  }
}

@mixin height-from($breakpoint) {
  $size: map-get($breakpoints, $breakpoint);
  @if ($size == 0) {
    @content;
  } @else {
    @media screen and (min-height: $size) {
      @content;
    }
  }
}

@mixin height-to($breakpoint) {
  $size: map-get($breakpoints, $breakpoint);
  @if ($size == 0) {
    @content;
  } @else {
    @media screen and (max-height: $size) {
      @content;
    }
  }
}

@mixin height-from-to($from, $to) {
  $min: map-get($breakpoints, $from);
  $max: map-get($breakpoints, $to) - 1px;

  @if ($min == 0) {
    @media screen and (max-height: $max) {
      @content;
    }
  } @else {
    @media screen and (min-height: $min) and (max-height: $max) {
      @content;
    }
  }
}