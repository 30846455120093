@import "src/app/styles";

.wrapper {
  @include box-size(100%, 100%);
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  overflow: hidden;
  transition: background-color 0.5s;
  gap: 4px;
  background: var(--bg-dialogue);

  &_dragOver {
    background-color: var(--bg-dialogue);
  }

  .btnDown {
    @include flex-column(flex-start);
    gap: 4px;
    position: absolute;
    bottom: 30px;
    right: 14px;
    z-index: 10;

    svg {
      fill: var(--text-fixed);
    }
  }

  .systemMsg{
    @include box-size(0, auto);
    display: flex;
    min-width: 100%;
  }

  .row {
    @include box-size(98%, auto);
    display: flex;
    transition: background-color .5s;
    border-radius: 12px;
  }
}
