@import "src/app/styles";

.wrapper {
  @include box-size(376px,auto);
  @include flex-column(flex-start);
  gap: 10px;

  .avatar{
    @include flex-column;
    margin-bottom: 8px;
  }

  .input{
    width: 100%;
  }

  .inputLastName{
    margin-bottom:  20px;
    width: 100%;
  }
}