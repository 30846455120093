@import "src/app/styles";

.wrapper {
  @include box-size(100%, 100%);
  @include flex-column(flex-start, flex-start);
  //background-color: var(--bg-dialogue);
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 100%;
  border-left: 1px solid var(--border-secondary);
  @include width-from(xl) {
    width: 450px;
  }

  .header {
    @include box-size(calc(100% - 24px), 114px);
    @include flex-column(flex-start);
    gap: 10px;
    background-color: var(--bg-secondary);
    padding: 12px 12px 0 12px;

    .title {
      @include box-size(100%, auto);
      @include flex;
      gap: 16px;

      svg {
        fill: var(--text-primary);
      }
    }

    .search {
      @include box-size(100%, auto);
      @include flex;
    }
  }

  .noFound{
    @include flex(center, flex-start);
    width: 100%;
    margin-top: 78px;
    overflow-y: auto;

    @include height-to(md){
      margin-top: 22px;
    }
  }


  .messages {
    @include box-size(calc(100% - 24px), calc(100% - 114px));
    @include flex-column(flex-start, flex-start);
    gap: 18px;
    background-color: var(--bg-primary);
    padding: 12px;
    overflow-y: auto;

    .item {
      @include box-size(100%, auto);
      //min-height: 64px;
      @include flex-column(flex-start, flex-start);
      cursor: pointer;
      gap: 2px;
      .date{

      }

      .msg{
        @include flex(flex-start,flex-start);
        gap: 8px;


        svg{
          fill: var(--control-primary);
          min-width: 22px;
          margin-top: -2px;
        }
      }

      .text{
        color: var(--text-primary);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }

      .highlight{
        color: var(--text-action) !important;
        background-color: inherit;
      }
    }
  }
}
