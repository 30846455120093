@import "src/app/styles/index";

.wrapper {
  position: relative;

  .volume {
    @include box-size(44px, 118px);
    @include flex;
    background-color: var(--bg-secondary);
    box-shadow: var(--box-shadow);
    border-radius: 22px;
    position: fixed;
    z-index: 200;
    transform: translate(-12px, -147px);

    &_bottom{
      transform: translate(-12px, 2px) !important;
    }

    .sliderVolume {
      height: 90px;

    }
  }
}
