@import "src/app/styles";

.wrapper {
  @include flex;
  @include box-size(100%, 100%);

  .body {
    @include flex(flex-start);
    @include box-size(100%, 100%);
    overflow-x: auto;
    gap: 8px;

    svg {
      cursor: pointer;
      fill: var(--text-secondary);
    }
  }

  .icons{
    gap: 4px!important;
  }
}
