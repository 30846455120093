@import "src/app/styles";

.wrapper {
  @include box-size(100%, 100%);
  @include flex;
  background-color: inherit;
  border-radius: 12px;
  position: relative;
  //overflow: hidden;
  flex-grow: 1;

  .menu {
    //@include box-size(150px, 50px);
    box-shadow: var(--box-shadow);
    position: absolute;
    top: 10px;
    left: 40px;
    border-radius: 12px;
    z-index: 100;
    background-color: var(--bg-secondary);
    padding: 8px 0;

    .item {
      min-width: 200px;
      height: 40px;
      @include flex();
      transition: background-color 0.5s;
      cursor: pointer!important;

      &:hover {
        background-color: var(--bg-primary);
      }
      svg{
        fill: var(--text-primary) !important;
      }
      .content {
        color: var(--text-primary);
        @include flex(flex-start);
        width: 86%;
        gap: 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        &_red {
          color: var(--text-negative) !important;
        }
      }
    }
  }

  .savingFile{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .error {
    display: none !important;
  }

  .loading {
    @include flex;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--bg-quaternary);
    width: 100%;
    height: 100%;
    z-index: 100;
    border-radius: 12px;
  }


  .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }

  svg {
    fill: var(--text-fixed);
  }


  .imgHidden {
    display: none;
  }
}

