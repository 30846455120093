@import "../../../../../app/styles/index";

.wrapper {
  position: fixed;
  z-index: 10000000;
}






