@import "src/app/styles";

.error {
  @include box-size(100%, 100%);
  @include flex;
  font-size: 18px;
  color: var(--error-color);
  box-shadow: var(--box-shadow-error) inset;
}

.loading {
  @include box-size(100%, 100%);
  @include flex;
  font-size: 18px;
  color: var(--error-color);
  overflow: hidden;
}
.wrapper{
  position: relative;

  .backBtn{
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 20;
    transform: translateY(-50%, -50%);
  }
}
