@import "src/app/styles";

.wrapper {
  @include box-size(100%, 100%);
  @include flex;
  gap: 1px;
  flex-wrap: wrap;
  border-radius: 12px;
  overflow: hidden;
  overflow-y: auto;
}

