@import "src/app/styles/index";

.wrapper {
  @include box-size(calc(416px - 48px), 100%);
  @include flex-column;
  gap: 24px;
  padding: 32px 24px;

  .currentSession {
    @include flex-column(space-between,space-between);
    width: 100%;
    gap: 12px;
  }

  .clearOther{
    width: 100%;
  }

  .otherSessions {
    @include flex-column(flex-start);
    overflow-y: auto;
    //gap: 12px;

    .item {
      width: 100%;
      @include flex(space-between);
      border-bottom: 1px solid var(--control-secondary);

      svg {
        cursor: pointer;
        fill: var(--control-primary);
      }

      .card {
        height: 94px;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .card {
    @include flex(flex-start);
    width: 100%;
    gap: 12px;

    .icon {
      @include flex;
      @include box-size(56px, 56px);
      border-radius: 50%;
      min-width: 56px;
      background-color: var(--bg-tertiary);

      svg {
        fill: var(--white);
      }

      &_current {
        background-color: var(--control-primary);

        svg {
          fill: white;
        }
      }
    }
  }
}