@import "src/app/styles/index";

.wrapper {
  @include flex-column(center, flex-start, 16);
  padding: 16px 20px;
  background-color: var(--bg-primary);
  border-radius: 20px;
  max-width: 335px;

  .header {
    @include flex(flex-start, flex-start);
    gap: 12px;

    .icon {
      min-width: 40px;
      @include box-size(40px, 40px);
      @include flex;
      background-color: var(--bg-secondary);
      border-radius: 50%;
    }

    .titles {
      @include flex-column(flex-start, flex-start, 12);
    }
  }
}

.addCompany {
  @include flex(space-between);
  @include box-size(calc(400px - 32px), 32px);
  gap: 12px;
  padding: 8px 16px;
  background-color: var(--bg-primary);
  border-radius: 12px;
  cursor: pointer;

  @include width-to(sm){
    width: auto;
    flex: 1 1 auto;
  }

  .plus {
    font-size: 24px;
    font-weight: 500;
    color: var(--text-action);
  }

  svg {
    fill: var(--control-primary)
  }
}
