@import "src/app/styles";

.wrapper {
  @include flex;
  gap: 8px;
  border: 1px solid var(--border-primary);
  color: var(--text-action);
  border-radius: 12px;
  text-align: center;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  transition: all .5s;
  font-weight: 600;

  .animateContent {
    @include flex;
    gap: 8px;
  }
}

.redText{
  color: var(--text-negative) !important;
}

.vertical {
  @include flex-column;
}

.primary {
  background-color: var(--control-primary);
  color: var(--text-fixed);

  &:hover {
    background-color: var(--control-primary-pressed);
  }
}

.bg-secondary {
  background-color: var(--bg-secondary);
  color: var(--text-action);
  fill: var(--text-action);
  border: 1px solid var(--bg-secondary);

  &:hover {
    border: 1px solid var(--border-primary-pressed);
  }
}

.secondary {
  color: var(--text-action-pressed);

  &:hover {
    border: 1px solid var(--border-primary-pressed);
  }
}

.shadow {
  color: var(--text-action-pressed);
  box-shadow: var(--box-shadow);
  border: none;
  background-color: var(--bg-secondary);

  &:hover {
    box-shadow: var(--box-shadow-active);
  }
}

.tertiary {
  border: none;
  color: var(--text-action);

  &:hover {
    color: var(--text-action-pressed);
  }
}

.negative {
  background-color: var(--control-negative);

  &:hover {
    color: var(--text-negative-pressed);
  }
}

.chips {
  //width: auto;
  border-radius: 20px;
  height: 40px !important;
  padding: 0 16px !important;
  min-width: fit-content;
}


.disabled {
  pointer-events: none !important;
  cursor: default;
  opacity: 0.4;
}

.error {
  color: var(--red);
  border: 1px solid var(--red);
}


.inherit {
  border: none;
  background-color: inherit;
  color: var(--text-primary);

  &_active {
    color: var(--text-action);
  }

  &:hover {
  }
}

.size-s {
  //width: 86px;
  height: 48px;
  font-size: 14px;
  line-height: 20px;
}

.size-m {
  width: 92px;
  height: 56px;
  font-size: 16px;
  line-height: 24px;
}

