@import "src/app/styles";

.wrapper {
  @include box-size(100%, 55px);
  @include flex-column(center, flex-end);
  gap: 8px;
  height: auto;
  padding-top: 6px;

  .row {
    @include box-size(100%, 50px);
    @include flex;
    gap: 12px;

    .code {
      @include box-size(100px, 100%);
      .inputCode {
        max-height: 56px;
        @include box-size(100px, 50px);
        @include flex;
        padding-left: 4px;
        background-color: var(--bg-primary);
        border: 1px solid var(--bg-primary);
        border-radius: 12px;
        cursor: pointer;
        transition: all .5s;

        &_focused {
          border: 1px solid var(--border-primary-pressed);
        }

        &__body {
          width: 100%;
          @include flex(flex-start);
          color: var(--text-inactive);
          gap: 4px;

          &_left {
            width: 70%;
            @include flex;
            gap: 4px;
          }
        }
      }
    }

    .input {
      @include box-size(calc(100% - 116px), 100%);
      @include flex(flex-start);
      background-color: var(--bg-primary);
      border: 1px solid var(--bg-primary);
      padding-left: 8px;
      border-radius: 12px;
      transition: all .5s;
      color: var(--text-primary);
      &_focused {
        border: 1px solid var(--border-primary-pressed);
      }
    }
  }

  .errorTitle {
    width: 100%;
    text-align: left;
    font-size: 14px;
    color: var(--text-negative);
  }
}

.contentDropdown {
  @include flex;
  @include box-size(auto, auto);
  background-color: var(--bg-secondary);
  padding: 12px;
  border-radius: 12px;
  box-shadow: var(--box-shadow);

  .body {
    @include flex-column(flex-start, flex-start);
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .item {
      @include flex(space-between);
      white-space: nowrap;
      width: 90%;
      min-height: 40px;
      gap: 16px;
      cursor: pointer;
      color: var(--text-primary);

      .left {
        @include flex;
        gap: 16px;
      }
    }
  }

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}