.wrapper {
  position: relative;

  .btn {
    cursor: pointer;
    display: flex;
  }

  .picker {
    position: absolute;
    bottom: 30px;
    right: 10px;
    box-shadow: var(--box-shadow);
    border-radius: 12px;
    z-index: 100;
  }
}


.EmojiPickerReact, .epr-main {
  background-color: var(--bg-primary) !important;

  .epr-body {
    .epr-emoji-list {

      .epr-emoji-category {
        &:nth-child(1) {
          .epr-emoji-category-label {
            visibility: hidden;

            &:before {
              visibility: visible;
              content: 'Часто используемые';
            }
          }
        }

        &:nth-child(2) {
          .epr-emoji-category-label {
            visibility: hidden;

            &:before {
              visibility: visible;
              content: 'Смайлы и люди';
            }
          }
        }

        &:nth-child(3) {
          .epr-emoji-category-label {
            visibility: hidden;

            &:before {
              visibility: visible;
              content: 'Животные и природа';
            }
          }
        }

        &:nth-child(4) {
          .epr-emoji-category-label {
            visibility: hidden;

            &:before {
              visibility: visible;
              content: 'Еда и напитки';
            }
          }
        }

        &:nth-child(5) {
          .epr-emoji-category-label {
            visibility: hidden;

            &:before {
              visibility: visible;
              content: 'Путешествия и места';
            }
          }
        }

        &:nth-child(6) {
          .epr-emoji-category-label {
            visibility: hidden;

            &:before {
              visibility: visible;
              content: 'Деятельность';
            }
          }
        }

        &:nth-child(7) {
          .epr-emoji-category-label {
            visibility: hidden;

            &:before {
              visibility: visible;
              content: 'Объекты';
            }
          }
        }

        &:nth-child(8) {
          .epr-emoji-category-label {
            visibility: hidden;

            &:before {
              visibility: visible;
              content: 'Символы';
            }
          }
        }

        &:nth-child(9) {
          .epr-emoji-category-label {
            visibility: hidden;

            &:before {
              visibility: visible;
              content: 'Флаги';
            }
          }
        }
      }
    }
  }

  .epr-emoji-category-label {
    background-color: var(--bg-03) !important;
  }


  .epr-search {
    background-color: var(--bg-1) !important;
  }

  [data-unified="1f469-200d-1f393"] {
    display: none;
  }

  [data-unified="1f468-200d-1f3eb"] {
    display: none;
  }

  [data-unified="1f469-200d-1f3eb"] {
    display: none;
  }

  [data-unified="1f468-200d-2696-fe0f"] {
    display: none;
  }

  [data-unified="1f469-200d-2696-fe0f"] {
    display: none;
  }

  [data-unified="1f468-200d-1f33e"] {
    display: none;
  }

  [data-unified="1f469-200d-1f33e"] {
    display: none;
  }

  [data-unified="1f468-200d-1f373"] {
    display: none;
  }

  [data-unified="1f469-200d-1f373"] {
    display: none;
  }

  [data-unified="1f468-200d-1f527"] {
    display: none;
  }

  [data-unified="1f469-200d-1f527"] {
    display: none;
  }

  [data-unified="1f468-200d-1f3ed"] {
    display: none;
  }

  [data-unified="1f469-200d-1f3ed"] {
    display: none;
  }

  [data-unified="1f468-200d-1f4bc"] {
    display: none;
  }

  [data-unified="1f469-200d-1f4bc"] {
    display: none;
  }

  [data-unified="1f468-200d-1f52c"] {
    display: none;
  }

  [data-unified="1f469-200d-1f52c"] {
    display: none;
  }

  [data-unified="1f468-200d-1f4bb"] {
    display: none;
  }

  [data-unified="1f469-200d-1f4bb"] {
    display: none;
  }

  [data-unified="1f468-200d-1f3a4"] {
    display: none;
  }

  [data-unified="1f469-200d-1f3a4"] {
    display: none;
  }

  [data-unified="1f468-200d-1f3a8"] {
    display: none;
  }

  [data-unified="1f469-200d-1f3a8"] {
    display: none;
  }

  [data-unified="1f468-200d-2708-fe0f"] {
    display: none;
  }

  [data-unified="1f469-200d-2708-fe0f"] {
    display: none;
  }

  [data-unified="1f468-200d-1f680"] {
    display: none;
  }

  [data-unified="1f469-200d-1f680"] {
    display: none;
  }

  [data-unified="1f468-200d-1f692"] {
    display: none;
  }

  [data-unified="1f46e-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f46e-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f575-fe0f"] {
    display: none;
  }

  [data-unified="1f575-fe0f-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f575-fe0f-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f482-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f482-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f477-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f477-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f473-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f473-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f9d9-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f9d9-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f9da-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f9da-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f9db-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f9db-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f9dc-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f9dc-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f9dd-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f9dd-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f9de-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f9de-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f9df-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f9df-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f486-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f486-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f487-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f487-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f6b6-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f6b6-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f3c3-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f3c3-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f46f-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f46f-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f9d6-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f9d6-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f9d7-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f9d7-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="26f7-fe0f"] {
    display: none;
  }

  [data-unified="1f3cc-fe0f"] {
    display: none;
  }

  [data-unified="1f3cc-fe0f-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f3cc-fe0f-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f3c4-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f3c4-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f6a3-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f6a3-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f3ca-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f3ca-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="26f9-fe0f"] {
    display: none;
  }

  [data-unified="26f9-fe0f-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="26f9-fe0f-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f3cb-fe0f"] {
    display: none;
  }

  [data-unified="1f3cb-fe0f-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f3cb-fe0f-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f6b4-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f6b4-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f6b5-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f6b5-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f938-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f938-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f93c-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f93c-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f93d-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f93d-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f93e-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f93e-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f939-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f939-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f9d8-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f9d8-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f469-200d-2764-fe0f-200d-1f48b-200d-1f468"] {
    display: none;
  }

  [data-unified="1f468-200d-2764-fe0f-200d-1f48b-200d-1f468"] {
    display: none;
  }

  [data-unified="1f469-200d-2764-fe0f-200d-1f48b-200d-1f469"] {
    display: none;
  }

  [data-unified="1f469-200d-2764-fe0f-200d-1f468"] {
    display: none;
  }

  [data-unified="1f468-200d-2764-fe0f-200d-1f468"] {
    display: none;
  }

  [data-unified="1f469-200d-2764-fe0f-200d-1f469"] {
    display: none;
  }

  [data-unified="1f468-200d-1f469-200d-1f466 "] {
    display: none;
  }

  [data-unified="1f468-200d-1f469-200d-1f467"] {
    display: none;
  }

  [data-unified="1f468-200d-1f469-200d-1f467-200d-1f466"] {
    display: none;
  }

  [data-unified="1f468-200d-1f469-200d-1f466-200d-1f466"] {
    display: none;
  }

  [data-unified="1f468-200d-1f469-200d-1f467-200d-1f467"] {
    display: none;
  }

  [data-unified="1f468-200d-1f468-200d-1f466"] {
    display: none;
  }

  [data-unified="1f468-200d-1f468-200d-1f467"] {
    display: none;
  }

  [data-unified="1f468-200d-1f468-200d-1f467-200d-1f466"] {
    display: none;
  }

  [data-unified="1f468-200d-1f468-200d-1f466-200d-1f466"] {
    display: none;
  }

  [data-unified="1f468-200d-1f468-200d-1f467-200d-1f467"] {
    display: none;
  }

  [data-unified="1f469-200d-1f469-200d-1f466"] {
    display: none;
  }

  [data-unified="1f469-200d-1f469-200d-1f467"] {
    display: none;
  }

  [data-unified="1f469-200d-1f469-200d-1f467-200d-1f466"] {
    display: none;
  }

  [data-unified="1f469-200d-1f469-200d-1f466-200d-1f466"] {
    display: none;
  }

  [data-unified="1f469-200d-1f469-200d-1f467-200d-1f467"] {
    display: none;
  }

  [data-unified="1f468-200d-1f466"] {
    display: none;
  }

  [data-unified="1f468-200d-1f466-200d-1f466"] {
    display: none;
  }

  [data-unified="1f468-200d-1f467"] {
    display: none;
  }

  [data-unified="1f468-200d-1f467-200d-1f466"] {
    display: none;
  }

  [data-unified="1f468-200d-1f467-200d-1f467"] {
    display: none;
  }

  [data-unified="1f469-200d-1f466"] {
    display: none;
  }

  [data-unified="1f469-200d-1f466-200d-1f466"] {
    display: none;
  }

  [data-unified="1f469-200d-1f467"] {
    display: none;
  }

  [data-unified="1f469-200d-1f467-200d-1f466"] {
    display: none;
  }

  [data-unified="1f469-200d-1f467-200d-1f467"] {
    display: none;
  }

  [data-unified="1f5e3-fe0f"] {
    display: none;
  }

  [data-unified="1f43f-fe0f"] {
    display: none;
  }

  [data-unified="1f54a-fe0f"] {
    display: none;
  }

  [data-unified="1f577-fe0f"] {
    display: none;
  }

  [data-unified="1f578-fe0f"] {
    display: none;
  }

  [data-unified="1f3f5-fe0f"] {
    display: none;
  }

  [data-unified="2618-fe0f"] {
    display: none;
  }

  [data-unified="1f336-fe0f"] {
    display: none;
  }

  [data-unified="1f37d-fe0f"] {
    display: none;
  }

  [data-unified="1f5fa-fe0f"] {
    display: none;
  }

  [data-unified="1f3d4-fe0f"] {
    display: none;
  }

  [data-unified="26f0-fe0f"] {
    display: none;
  }

  [data-unified="1f3d5-fe0f"] {
    display: none;
  }

  [data-unified="1f3d6-fe0f"] {
    display: none;
  }

  [data-unified="1f3dc-fe0f"] {
    display: none;
  }

  [data-unified="1f3dd-fe0f"] {
    display: none;
  }

  [data-unified="1f3de-fe0f"] {
    display: none;
  }

  [data-unified="1f3df-fe0f"] {
    display: none;
  }

  [data-unified="1f3db-fe0f"] {
    display: none;
  }

  [data-unified="1f3d7-fe0f"] {
    display: none;
  }

  [data-unified="1f3d8-fe0f"] {
    display: none;
  }

  [data-unified="1f3da-fe0f"] {
    display: none;
  }

  [data-unified="26fa"] {
    display: none;
  }

  [data-unified="1f3d9-fe0f"] {
    display: none;
  }

  [data-unified="1f3ce-fe0f"] {
    display: none;
  }

  [data-unified="1f3cd-fe0f"] {
    display: none;
  }

  [data-unified="1f6e3-fe0f"] {
    display: none;
  }

  [data-unified="1f6e4-fe0f"] {
    display: none;
  }

  [data-unified="1f6e2-fe0f"] {
    display: none;
  }

  [data-unified="26fd"] {
    display: none;
  }

  [data-unified="26f5"] {
    display: none;
  }

  [data-unified="1f6f3-fe0f"] {
    display: none;
  }

  [data-unified="26f4-fe0f"] {
    display: none;
  }

  [data-unified="1f6e5-fe0f"] {
    display: none;
  }

  [data-unified="2708-fe0f"] {
    display: none;
  }

  [data-unified="1f6e9-fe0f"] {
    display: none;
  }

  [data-unified="1f6f0-fe0f"] {
    display: none;
  }

  [data-unified="1f6ce-fe0f"] {
    display: none;
  }

  [data-unified="231b"] {
    display: none;
  }

  [data-unified="23f3"] {
    display: none;
  }

  [data-unified="231a"] {
    display: none;
  }

  [data-unified="23f0"] {
    display: none;
  }

  [data-unified="23f1-fe0f"] {
    display: none;
  }

  [data-unified="23f2-fe0f"] {
    display: none;
  }

  [data-unified="1f570-fe0f"] {
    display: none;
  }

  [data-unified="1f321-fe0f"] {
    display: none;
  }

  [data-unified="2600-fe0f"] {
    display: none;
  }

  [data-unified="2601-fe0f"] {
    display: none;
  }

  [data-unified="26c5"] {
    display: none;
  }

  [data-unified="26c8-fe0f"] {
    display: none;
  }

  [data-unified="1f324-fe0f"] {
    display: none;
  }

  [data-unified="1f325-fe0f"] {
    display: none;
  }

  [data-unified="1f326-fe0f"] {
    display: none;
  }

  [data-unified="1f327-fe0f"] {
    display: none;
  }

  [data-unified="1f328-fe0f"] {
    display: none;
  }

  [data-unified="1f329-fe0f"] {
    display: none;
  }

  [data-unified="1f32a-fe0f"] {
    display: none;
  }

  [data-unified="1f32b-fe0f"] {
    display: none;
  }

  [data-unified="1f32c-fe0f"] {
    display: none;
  }

  [data-unified="2602-fe0f"] {
    display: none;
  }

  [data-unified="2614"] {
    display: none;
  }

  [data-unified="26f1-fe0f"] {
    display: none;
  }

  [data-unified="2744-fe0f"] {
    display: none;
  }

  [data-unified="2603-fe0f"] {
    display: none;
  }

  [data-unified="2604-fe0f"] {
    display: none;
  }

  [data-unified="1f397-fe0f"] {
    display: none;
  }

  [data-unified="1f39f-fe0f"] {
    display: none;
  }

  [data-unified="1f396-fe0f"] {
    display: none;
  }

  [data-unified="26be"] {
    display: none;
  }

  [data-unified="26f8-fe0f"] {
    display: none;
  }

  [data-unified="1f579-fe0f"] {
    display: none;
  }

  [data-unified="2660-fe0f"] {
    display: none;
  }

  [data-unified="2665-fe0f"] {
    display: none;
  }

  [data-unified="2666-fe0f"] {
    display: none;
  }

  [data-unified="2663-fe0f"] {
    display: none;
  }

  [data-unified="1f5bc-fe0f"] {
    display: none;
  }

  [data-unified="1f576-fe0f"] {
    display: none;
  }

  [data-unified="1f6cd-fe0f"] {
    display: none;
  }

  [data-unified="26d1-fe0f"] {
    display: none;
  }

  [data-unified="1f399-fe0f"] {
    display: none;
  }

  [data-unified="1f39a-fe0f"] {
    display: none;
  }

  [data-unified="1f39b-fe0f"] {
    display: none;
  }

  [data-unified="260e-fe0f"] {
    display: none;
  }

  [data-unified="1f5a5-fe0f"] {
    display: none;
  }

  [data-unified="2328-fe0f"] {
    display: none;
  }

  [data-unified="1f5a8-fe0f"] {
    display: none;
  }

  [data-unified="1f5b1-fe0f"] {
    display: none;
  }

  [data-unified="1f5b2-fe0f"] {
    display: none;
  }

  [data-unified="1f39e-fe0f"] {
    display: none;
  }

  [data-unified="1f4fd-fe0f"] {
    display: none;
  }

  [data-unified="1f56f-fe0f"] {
    display: none;
  }

  [data-unified="1f5de-fe0f"] {
    display: none;
  }

  [data-unified="1f3f7-fe0f"] {
    display: none;
  }

  [data-unified="1f5f3-fe0f"] {
    display: none;
  }

  [data-unified="270f-fe0f"] {
    display: none;
  }

  [data-unified="2712-fe0f"] {
    display: none;
  }

  [data-unified="1f58a-fe0f"] {
    display: none;
  }

  [data-unified="1f58c-fe0f"] {
    display: none;
  }

  [data-unified="1f58d-fe0f"] {
    display: none;
  }

  [data-unified="1f5c2-fe0f"] {
    display: none;
  }

  [data-unified="1f5d2-fe0f"] {
    display: none;
  }

  [data-unified="1f5d3-fe0f"] {
    display: none;
  }

  [data-unified="1f587-fe0f"] {
    display: none;
  }

  [data-unified="2702-fe0f"] {
    display: none;
  }

  [data-unified="1f5c3-fe0f"] {
    display: none;
  }

  [data-unified="1f5c4-fe0f"] {
    display: none;
  }

  [data-unified="1f5d1-fe0f"] {
    display: none;
  }

  [data-unified="1f5dd-fe0f"] {
    display: none;
  }

  [data-unified="26cf-fe0f"] {
    display: none;
  }

  [data-unified="2692-fe0f"] {
    display: none;
  }

  [data-unified="1f6e0-fe0f"] {
    display: none;
  }

  [data-unified="1f5e1-fe0f"] {
    display: none;
  }

  [data-unified="2694-fe0f"] {
    display: none;
  }

  [data-unified="1f6e1-fe0f"] {
    display: none;
  }

  [data-unified="2699-fe0f"] {
    display: none;
  }

  [data-unified="1f5dc-fe0f"] {
    display: none;
  }

  [data-unified="2696-fe0f"] {
    display: none;
  }

  [data-unified="26d3-fe0f"] {
    display: none;
  }

  [data-unified="2697-fe0f"] {
    display: none;
  }

  [data-unified="1f6cf-fe0f"] {
    display: none;
  }

  [data-unified="1f6cb-fe0f"] {
    display: none;
  }

  [data-unified="26b0-fe0f"] {
    display: none;
  }

  [data-unified="26b1-fe0f"] {
    display: none;
  }

  [data-unified="2622-fe0f"] {
    display: none;
  }

  [data-unified="2623-fe0f"] {
    display: none;
  }

  [data-unified="2b06-fe0f"] {
    display: none;
  }

  [data-unified="2197-fe0f"] {
    display: none;
  }

  [data-unified="27a1-fe0f"] {
    display: none;
  }

  [data-unified="2198-fe0f"] {
    display: none;
  }

  [data-unified="2b07-fe0f"] {
    display: none;
  }

  [data-unified="2199-fe0f"] {
    display: none;
  }

  [data-unified="2b05-fe0f"] {
    display: none;
  }

  [data-unified="2196-fe0f"] {
    display: none;
  }

  [data-unified="2195-fe0f"] {
    display: none;
  }

  [data-unified="2194-fe0f"] {
    display: none;
  }

  [data-unified="21a9-fe0f"] {
    display: none;
  }

  [data-unified="21aa-fe0f"] {
    display: none;
  }

  [data-unified="2934-fe0f"] {
    display: none;
  }

  [data-unified="2935-fe0f"] {
    display: none;
  }

  [data-unified="269b-fe0f"] {
    display: none;
  }

  [data-unified="1f549-fe0f"] {
    display: none;
  }

  [data-unified="2721-fe0f"] {
    display: none;
  }

  [data-unified="2638-fe0f"] {
    display: none;
  }

  [data-unified="262f-fe0f"] {
    display: none;
  }

  [data-unified="271d-fe0f"] {
    display: none;
  }

  [data-unified="2626-fe0f"] {
    display: none;
  }

  [data-unified="262a-fe0f"] {
    display: none;
  }

  [data-unified="262e-fe0f"] {
    display: none;
  }

  [data-unified="2649"] {
    display: none;
  }

  [data-unified="264b"] {
    display: none;
  }

  [data-unified="25b6-fe0f"] {
    display: none;
  }

  [data-unified="23ed-fe0f"] {
    display: none;
  }

  [data-unified="23ef-fe0f"] {
    display: none;
  }

  [data-unified="25c0-fe0f"] {
    display: none;
  }

  [data-unified="23ee-fe0f"] {
    display: none;
  }

  [data-unified="23f8-fe0f"] {
    display: none;
  }

  [data-unified="23f9-fe0f"] {
    display: none;
  }

  [data-unified="23fa-fe0f"] {
    display: none;
  }

  [data-unified="23cf-fe0f"] {
    display: none;
  }

  [data-unified="1f4f3"] {
    display: none;
  }

  [data-unified="2716-fe0f"] {
    display: none;
  }

  [data-unified="203c-fe0f"] {
    display: none;
  }

  [data-unified="2049-fe0f"] {
    display: none;
  }

  [data-unified="267b-fe0f"] {
    display: none;
  }

  [data-unified="269c-fe0f"] {
    display: none;
  }

  [data-unified="2611-fe0f"] {
    display: none;
  }

  [data-unified="303d-fe0f"] {
    display: none;
  }

  [data-unified="2733-fe0f"] {
    display: none;
  }

  [data-unified="2734-fe0f"] {
    display: none;
  }

  [data-unified="2747-fe0f"] {
    display: none;
  }

  [data-unified="00a9-fe0f"] {
    display: none;
  }

  [data-unified="00ae-fe0f"] {
    display: none;
  }

  [data-unified="2122-fe0f"] {
    display: none;
  }

  [data-unified="0023-fe0f-20e3"] {
    display: none;
  }

  [data-unified="002a-fe0f-20e3"] {
    display: none;
  }

  [data-unified="0030-fe0f-20e3"] {
    display: none;
  }

  [data-unified="0031-fe0f-20e3"] {
    display: none;
  }

  [data-unified="0032-fe0f-20e3"] {
    display: none;
  }

  [data-unified="0033-fe0f-20e3"] {
    display: none;
  }

  [data-unified="0034-fe0f-20e3"] {
    display: none;
  }

  [data-unified="0035-fe0f-20e3"] {
    display: none;
  }

  [data-unified="0036-fe0f-20e3"] {
    display: none;
  }

  [data-unified="0037-fe0f-20e3"] {
    display: none;
  }

  [data-unified="0038-fe0f-20e3"] {
    display: none;
  }

  [data-unified="0039-fe0f-20e3"] {
    display: none;
  }

  [data-unified="1f170-fe0f"] {
    display: none;
  }

  [data-unified="1f171-fe0f"] {
    display: none;
  }

  [data-unified="2139-fe0f"] {
    display: none;
  }

  [data-unified="24c2-fe0f"] {
    display: none;
  }

  [data-unified="1f17e-fe0f"] {
    display: none;
  }

  [data-unified="1f17f-fe0f"] {
    display: none;
  }

  [data-unified="1f202-fe0f"] {
    display: none;
  }

  [data-unified="1f237-fe0f"] {
    display: none;
  }

  [data-unified="3297-fe0f"] {
    display: none;
  }

  [data-unified="263a-fe0f"] {
    display: none;
  }

  [data-unified="3299-fe0f"] {
    display: none;
  }

  [data-unified="25fb-fe0f"] {
    display: none;
  }

  [data-unified="25aa-fe0f"] {
    display: none;
  }

  [data-unified="25ab-fe0f"] {
    display: none;
  }

  [data-unified="1f468-200d-1f469-200d-1f466"] {
    display: none;
  }

  [data-unified="2639-fe0f"] {
    display: none;
  }

  [data-unified="2620-fe0f"] {
    display: none;
  }

  [data-unified="1f573-fe0f"] {
    display: none;
  }

  [data-unified="1f441-fe0f-200d-1f5e8-fe0f"] {
    display: none;
  }

  [data-unified="1f5ef-fe0f"] {
    display: none;
  }

  [data-unified="1f590-fe0f"] {
    display: none;
  }

  [data-unified="270c-fe0f"] {
    display: none;
  }

  [data-unified="261d-fe0f"] {
    display: none;
  }

  [data-unified="270d-fe0f"] {
    display: none;
  }

  [data-unified="1f441-fe0f"] {
    display: none;
  }

  [data-unified="1f471-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f471-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f64d-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f64d-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f64e-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f64e-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f645-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f645-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f646-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f646-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f481-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f481-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f64b-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f64b-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f647-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f647-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f926-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f926-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f937-200d-2642-fe0f"] {
    display: none;
  }

  [data-unified="1f937-200d-2640-fe0f"] {
    display: none;
  }

  [data-unified="1f468-200d-2695-fe0f"] {
    display: none;
  }

  [data-unified="1f469-200d-2695-fe0f"] {
    display: none;
  }
  [data-unified="1f468-200d-1f393"] {
    display: none;
  }

  [data-unified="1f469-200d-1f692"] {
    display: none;
  }
  [data-unified="1f574-fe0f"] {
    display: none;
  }

  [data-unified="1f46b"] {
    display: none;
  }
  [data-unified="26a1"] {
    display: none;
  }

  [data-unified="2709-fe0f"] {
    display: none;
  }
  [data-unified="1f58b-fe0f"] {
    display: none;
  }
}