@import "src/app/styles/index";

.wrapper {
  @include box-size(calc(462px - 36px), 100%);
  @include flex-column(flex-start, flex-start);
  max-height: calc(100vh - 22px);
  overflow-y: auto;
  padding: 18px;

  .header {
    width: 100%;
    @include flex(flex-end);
    gap: 8px;

    .more {
      transform: rotate(90deg);
      margin-bottom: 5px;
    }

    svg {
      cursor: pointer;
      fill: var(--text-action);
    }
  }

  .avatar {
    width: 100%;
    @include flex;
    margin-top: 14px;
  }

  .description {
    @include flex(space-between);
    width: 100%;
    margin-top: 32px;

    .title {
      width: 90%;
      @include flex-column(flex-start, flex-start);
    }

    svg {
      cursor: pointer;
    }

  }

  .slider {
    @include flex;
    height: 12px;
    width: 100%;
    margin-top: 20px;
  }

  .time {
    @include flex(space-between);
    margin-top: 4px;
    width: 100%;
  }

  .controls {
    @include flex;
    width: 100%;
    gap: 20px;

    svg {
      cursor: pointer;
      fill: var(--control-primary);
    }

    .playPause {

    }
  }

  .actions {
    @include flex(flex-start);
    width: 100%;
    gap: 20px;
    margin-top: 26px;

    svg {
      cursor: pointer;
      fill: var(--control-primary);
    }
  }

  .list {
    @include flex-column(flex-start);
    margin-top: 8px;
    width: 100%;
    min-width: 100%;
    gap: 8px;
  }
}

