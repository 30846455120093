@import "src/app/styles";

.wrapper {
  @include flex;
  color: var(--text-primary);
  border-radius: 50%;
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: all .5s;
  svg{
    fill: var(--text-primary);
  }
}

.primary {
  background-color: var(--control-primary);
  color: var(--text-fixed);


  &:hover {
    background-color: var(--control-primary-pressed);
  }
}

.inherit {
  will-change: transform;
  &:hover {
    transform: scale(1.1);
  }
}

.secondary {
  background-color: inherit;
  color: var(--text-action-pressed);
  transition: all .5s;

  &:hover {
    //background-color: var(--control-primary);
  }
}

.tertiary {
  border: none;
  color: var(--text-fixed) !important;
  background-color: var(--control-tertiary) !important;

  &:hover {
    color: var(--control-tertiary-active);
  }
}

.active {
  background-color: var(--main-active);
  fill: var(--main-active);
}

.disabled {
  pointer-events: none;
  cursor: default;
}

.negative {
  background-color: var(--control-negative);

  &:hover {
    color: var(--text-negative-pressed);
  }
}

.error {
  background-color: var(--control-negative);
}
