@import "src/app/styles";
.wrapper {
  @include box-size(calc(100% - 1px), calc(100% - 16px));
  @include flex-column(flex-start);
  background-color: var(--bg-secondary);
  padding-top: 16px;
  gap: 8px;
  border-right: 1px solid var(--border-secondary);


  .header {
    @include box-size(calc(100% - 32px), 48px);
    @include flex(space-between);
    padding: 0 16px;

    .icons{
      @include flex;
      gap: 24px;

      svg{
        fill: var(--text-primary);
      }
    }
  }

  .list {
    @include flex(center, flex-start);
    flex: 1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
