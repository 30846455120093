@import "../../../../../app/styles/index";

.wrapper {
  @include box-size(416px, 100%);
  @include flex-column(flex-start, center);
  color: var(--text-primary);
  max-height: 600px;

  .header {
    width: 90%;
    @include flex-column();
    gap: 4px;
    margin-top: 28px;

    .title {
      width: 100%;
      text-align: left;
    }

  }

  .body {
    @include flex-column;
    gap: 16px;
    width: 90%;
    flex: 1;
    margin-top: 16px;

    .inputsName{
      @include flex-column;
      gap: 16px;
      width: 100%;
    }

    .inputPhone{
      @include flex;
      align-items: flex-end;
      gap: 8px;
      width: 100%;
    }
  }

  .footer {
    @include flex;
    gap: 16px;
    width: 90%;
    margin-top: 28px;
    margin-bottom: 28px;
  }

}