@import "src/app/styles/index";

.wrapper {
  @include box-size(auto, 100%);
  @include flex;
  gap: 30px;
  flex-wrap: wrap;
  max-height: 90vh;
  max-width: 80vw;

  overflow-y: auto;

  @include width-to('sm') {
    max-width: 96vw;
  }

  @include width-from('xl') {
    max-width: 1200px;
  }

  .form {
    @include flex-column();
    margin: 30px 0 30px 30px;
    gap: 20px;
    width: 400px;

    @include width-to('sm') {
      margin: 10px 0;
    }

  }

  .img {
    @include flex;
    margin: 30px 30px 30px 0;

    svg {
      @include box-size(80%, 90%)

    }
  }
}