@import "src/app/styles";

.wrapper {
  @include box-size(100%, 100%);
  @include flex;
  background-color: var(--bg-secondary);

  .list {
    @include flex-column( flex-start, center);
    @include box-size(100%, calc(100% - 16px));
    padding-top: 16px;

    .item{
      @include flex-column;
      @include box-size(88px, 72px);
      gap: 4px;
      cursor: pointer;
      position: relative;

      svg{
        fill: var(--control-tertiary);
      }

      &_active{
        background-color: var(--bg-primary);

        svg{
          fill: var(--control-tertiary-active);
        }
      }
      
      .counter{
        position: absolute;
        top: 8px;
        left: 48px;
        transform: translateX(0px);
      }
    }
  }
}
