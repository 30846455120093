@import "src/app/styles";

.select {
  @include flex;
  gap: 10px;
  padding: 5px 12px;
  border-radius: 16px;
  background-color: var(--bg-primary) !important;
  color: var(--text-primary);
  cursor: pointer;
}

.dropdown {
  @include box-size(100%, 100%);
  @include flex-column(flex-start, flex-end);
  padding: 12px 0;
  min-width: 100px;

  .item {
    padding: 0 12px;
    @include flex;
    @include box-size(100%, 28px);
    font-weight: 600;
    transition: all 0.5s;
    cursor: pointer;

    &_active{
      color: var(--text-primary);
      background-color: var(--bg-primary);
    }

    &:hover {
      color: var(--main-active);
      background-color: var(--bg-02);
    }
  }
}
.inherit {
  border: none;
  background-color: inherit;
  color: var(--text-primary);

  &_active{
    color: var(--text-action);
  }

  &:hover {
  }
}