@import "src/app/styles/index";

.wrapper {
  @include flex-column(flex-start, flex-start);
  @include box-size(100%, auto);
  padding-bottom: 10px;

  .addMembers{
    @include flex;
    @include box-size(100%, auto);
    gap: 12px;
    margin-left: 16px;
    cursor: pointer;

  }

  .list{
    @include flex-column(flex-start, flex-start);
    @include box-size(90%, auto);
    margin-left: 24px;
    gap: 8px;

    .item{
      @include flex(space-between);
      @include box-size(100%, auto);
    }
  }
}