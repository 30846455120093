@import "src/app/styles/index";

.wrapper {
  @include box-size(calc(541px - 40px), 100%);
  @include flex-column(flex-start);
  max-width: 541px;
  gap: 8px;
  background-color: var(--bg-primary);
  border-radius: 20px;
  padding: 0 20px 24px 20px;

  @include width-to(sm){
    @include box-size(calc(99vw - 40px) , 100%);
    padding: 20px 20px 24px 20px;
  }

  .container{
    @include box-size(100%, auto);
    @include flex-column(flex-start);
    background-color: var(--bg-secondary);
    margin-top: 81px;
    border-radius: 12px;
    position: relative;
    min-height: 200px;
    padding-bottom: 18px;
    margin-bottom: 10px;

    .avatar{
      position: absolute;
      top: -70px;
      left: 33%;
      //transform: translateX(-50%);
      border: 14px solid var(--bg-primary);
      border-radius: 10px 10px 50% 50%;
    }

    .description{
      padding: 0 20px;
      margin-top: 112px;
      width: calc(100% - 40px);
      @include flex-column(flex-start);
      gap: 4px;

    }
  }
}