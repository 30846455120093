@import "src/app/styles";

.wrapper {
  @include box-size(100%, 100vh);
  @include flex(flex-start);
  background-color: var(--bg-primary);
  overflow: hidden;
  position: relative;

  .navbar {
    @include box-size(88px, 100%);
    border-right: 2px solid var(--border-secondary);
    min-width: 88px;
  }

  .outlet {
    @include box-size(calc(100% - 88px), 100%);
  }

  .player{
    @include box-size(100%, auto);
    z-index: 1000;
    position: fixed;
    bottom: 0;
  }
}
