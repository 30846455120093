@import "../../../../../app/styles/index";

.wrapper {
  @include box-size(calc(391px - 32px), auto);
  @include flex-column(flex-start, center);
  padding: 16px;

  .header {
    align-self: flex-start;
    margin-top: 14px;
  }
  .avatar {
    margin-top: 38px;
  }

  .inputs{
    @include flex-column;
    gap: 16px;
    margin-top: 14px;
    width: 100%;
  }
  .btn{
    margin-top: 24px;
    width: 100%;
  }
}