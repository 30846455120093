@import "src/app/styles/index";

.wrapper {
  @include box-size(416px, auto);
  @include flex-column(flex-start, flex-start);
  max-height: calc(100vh - 22px);
  @include height-from(sm){
    max-height: calc(100vh - 50px);
  }

  .header {
    @include flex-column();
    width: calc(100% - 48px);
    gap: 4px;
    margin: 24px 24px 0 24px;
    color: var(--text-primary);

    .search {
      width: 100%;
    }
  }

  .tabs {
    @include box-size(calc(100% - 16px), 48px);
    @include flex(flex-start);
    min-height: 48px;
    padding-left: 16px;
    margin-bottom: 8px;
  }

  .body {
    @include flex-column(flex-start, flex-end);
    @include scrollBarVertical;
    width: 100%;
    flex: 1;
    //margin-left: 24px;
    gap: 20px;
    overflow-y: auto;
    max-height: 65vh;

    .list {
      @include flex-column(flex-start, flex-start, 12);
      width: 94%;
      padding: 12px 0;
    }
  }

  .footer {
    width: calc(100% - 48px);
    padding: 0 24px;
    margin-top: 12px;
    margin-bottom: 24px;
  }
}