@import "src/app/styles/index";

.wrapper {
  @include flex-column();
  @include box-size(100%, auto);
  gap: 4px;
  flex: 1;
  background-color: var(--bg-secondary);
  border-radius: 12px;
  //margin-bottom: 24px;
  position: relative;

  .addMembers {
    position: absolute;
    top: 48px;
    right: 12px;

    svg {
      fill: var(--text-primary);
    }
  }

  .tabBar {
    @include box-size(100% , auto);
    padding-top: 16px;
    padding-bottom: 6px;
    position: relative;
  }

  .mediaList {
    @include box-size(100%, auto);
    //margin-top: 10px;
    min-height: 100px;
    max-height: 374px;
    overflow-y: auto;
    color: var(--text-action);
    @include scrollBarVertical;

    .audios{
      @include flex-column();
      gap: 8px;
    }

    .members, .documents, .audios {
      padding: 10px 20px;

    }
  }
}