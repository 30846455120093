@import "src/app/styles/index";

.wrapper {
  @include box-size(416px, 100%);
  @include flex-column(flex-start, center);
  max-height: calc(100vh - 22px);
  overflow-y: auto;

  @include height-from(sm){
    max-height: calc(100vh - 70px);
  }

  .header {
    @include flex-column();
    width: calc(100% - 48px);
    gap: 4px;
    margin: 24px 24px 0 24px;
    color: var(--text-primary);

    .search {
      width: 100%;
    }

  }

  .list {
    @include scrollBarVertical;
    margin-top: 8px;
    width: 94%;
    overflow-y: auto;
    padding: 12px;
  }

  .footer {
    width: 90%;
    margin-top: 16px;
    margin-bottom: 28px;


    svg {
      fill: var(--text-action) !important;
    }
  }
}