@import "src/app/styles";

.wrapper {
  @include flex;
  width: calc(100% - 24px);
  gap: 12px;
  padding: 4px 12px;
  border-radius: 6px;
  color: var(--white);
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  //background-color: var(--bg-secondary);

  .indicator{
    @include box-size(16px, 16px);
    min-width: 16px;
    border-radius: 50%;
  }
}


