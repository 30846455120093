@import "src/app/styles";

.wrapper {
  @include flex;
  cursor: crosshair;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
