@import "src/app/styles/index";

.wrapper {
  @include box-size(auto, 100%);
  @include flex;
  gap: 30px;
  flex-wrap: wrap;
  max-height: 90vh;
  min-height: 200px;
  max-width: 1200px;
  overflow-y: auto;
padding: 0 24px;
  @include width-to('sm') {
    width: 96vw;
  }
}