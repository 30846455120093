@import "src/app/styles";


.wrapper {
  @include flex-column(flex-start, flex-start);
  @include box-size(calc(100% - 24px), 100%);
  padding: 0 12px;
  position: relative;

  .main {
    @include flex(space-between);
    @include box-size(100%, 100%);

    svg {
      fill: var(--text-secondary);
    }

    .left {
      @include flex(flex-start);
      gap: 10px;
      flex: 1;
      width: 0;
    }

    .calls {
      cursor: pointer;
    }

    .right {
    }

  }
}