@import "src/app/styles";

.wrapper {
  @include box-size(100%, 100%);
  @include flex-column(flex-start);


  .header {
    @include box-size(calc(100% - 24px), 70px);
    @include flex;
    padding: 0 12px;
    background-color: var(--bg-secondary);

    svg{
      fill: var(--text-primary);
    }
  }

  .list {
    @include box-size(calc(100% - 24px), auto);
    padding: 12px;
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    overflow: auto;
    transition: background-color 0.5s;
    gap: 4px;
    position: relative;

    .noMessages{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
    }

    .row {
      @include box-size(100%, auto);
      display: flex;
      align-items: center;

      .message {
        padding: 4px 12px;
        background-color: var(--bg-secondary);
        border-radius: 12px 12px 12px 0;
        max-width: 90%;
        word-wrap: break-word;
        color: var(--text-primary);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        word-break: break-word;
        white-space: pre-wrap;
        &_my{
          border-radius: 12px  12px 0 12px;
        }
      }
    }

  }

  .input {
    @include box-size(calc(100% - 24px), auto);
    @include flex;
    min-height: 46px;
    padding: 12px 12px 0 12px;
    background-color: var(--bg-secondary);

    .sendBtn {
      @include flex(center, flex-end);
      height: calc(100% - 12px);
      padding-bottom: 12px;
    }
  }
}
