@import "src/app/styles/index";

.wrapper {
  @include flex(flex-start);
  gap: 8px;
  cursor: pointer;
  //min-height: 56px;
  width: 100%;

  .playPauseIcon {
    @include flex;
    width: 47px;
    height: 47px;
    border-radius: 8px;
    background-color: var(--control-primary);
    min-width: 47px;

    svg {
      fill: white;
    }
  }

  .caption {
    max-width: 290px;
    width: calc(100% - 45px);
    @include flex-column(flex-start, flex-start);

    &_bottom {
      width: auto;

      .timer {
        @include flex(flex-start);
        font-size: 14px;
        line-height: 20px;
        color: var(--text-secondary);
      }
    }

    .description{
      @include flex(flex-start);
      width: 100%;

      .dot{
        margin: 0 6px;
        background-color: #717394;
        @include box-size(4px, 4px);
        border-radius: 50%;
      }
    }

  }

  svg {
    fill: var(--text-primary);
  }

}

