@import "src/app/styles/index";

.wrapper {
  @include box-size(416px, 100%);
  @include flex-column(flex-start, center);
  max-height: calc(100vh - 22px);
  overflow-y: auto;

  @include height-from(sm) {
    max-height: calc(100vh - 80px);
  }

  .header {
    @include flex-column();
    width: calc(100% - 48px);
    margin: 32px 24px 12px 24px;
    color: var(--text-primary);

    .chatInfo{
      margin-top: 12px;
      background-color: var(--bg-primary);
      border-radius: 12px;
      padding: 16px;
    }

    .groupSettings {
      @include flex;
      margin-top: 12px;
      width: 100%;
      gap: 12px;

      .name{
        height: 60px;
      }
    }

    .search {
      //margin-top: 12px;
      margin-bottom: 6px;
      width: 100%;
    }

    .description {
      margin-top: 12px;
      border: 1px solid var(--control-secondary);
      border-radius: 12px;
      width: calc(100% - 24px);
      padding: 12px;
      height: 50px;
      overflow: auto;
    }

    .switch {
      @include flex-column(flex-start,flex-start);
      width: 100%;
      margin-top: 18px;
      border-bottom: 1px solid var(--border-secondary);

      svg {
        stroke: var(--text-action) !important;
      }
    }
  }

  .selected{
    @include flex(flex-start);
    @include box-size(calc(100% - 24px), auto);
    @include scrollBarVertical;
    margin-top: 6px;
    margin-left: 24px;
    max-height: 120px;
    margin-bottom: 18px;
    flex: 1 0 auto;
    flex-wrap: wrap;
    gap: 6px;
    overflow-y: auto;

    @include height-to('sm'){
      max-height: 50px;
    }

    .item{
      @include flex;
      gap: 6px;
      padding: 8px 14px;
      background-color: var(--control-primary);
      border-radius: 12px;
      color: var(--text-fixed);

      .icon{
        @include flex;
        cursor: pointer;
        margin-top: 2px;
        svg{
          fill: var(--text-fixed);
        }
      }
    }
  }

  .tabBar{
    @include box-size(calc(100% - 24px), auto);
    margin-left: 24px;
  }

  .list {
    @include scrollBarVertical;
    @include flex-column(flex-start);
    gap: 12px;
    margin-top: 20px;
    width: calc(100% - 24px);
    padding: 0 12px;

    overflow-y: auto;
    //min-height: 100%;
  }

  .footer {
    @include flex-column;
    gap: 12px;
    width: 90%;
    margin-top: 16px;
    margin-bottom: 28px;


    svg {
      fill: var(--text-action) !important;
    }
  }
}