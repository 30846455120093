@import "src/app/styles";
.wrapper {
  padding: 40px 0;
  @include box-size(440px, auto);
  @include flex-column;
  background-color: var(--bg-secondary);
  border-radius: 20px;
  position: relative;

  .backArrow {
    width: 40px;
    height: 40px;
    border: 8px solid var(--bg-primary);
    border-radius: 50%;
    background-color: var(--bg-secondary);
    position: absolute;
    top: 30px;
    left: 0;
    transform: translateX(-50%);
    cursor: pointer;

    svg{
      fill: var(--text-primary);
    }

    @include flex;
    @include width-to('sm') {
      top: 0;
      left: 30px;
      transform: translateY(-50%);
    }
  }

  .steps{
    margin-top: 8px;
    margin-bottom: 28px;
  }
}
