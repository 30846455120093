@import "src/app/styles";

.wrapper {
  @include box-size(100%, auto);

  .tagUsers {
    @include flex-column(flex-start);
    @include box-size(calc(100% - 32px), 100%);
    padding: 8px 16px;
    gap: 12px;
    max-height: 300px;
    min-height: 40px;
    overflow-y: auto;
    background-color: var(--bg-primary);
  }

  .header {
    @include flex(flex-start);
    @include box-size(calc(100% - 32px), 70px);
    gap: 14px;
    padding: 8px 16px;
    background-color: var(--bg-secondary);

    .icon, .close {
      cursor: pointer;

      svg {
        fill: var(--text-action);
      }
    }

    .description {
      flex: 1;
      width: 0;
    }
  }

  .main {
    @include flex(center, flex-end);
    @include box-size(calc(100% - 32px), auto);
    gap: 14px;
    padding: 0 16px;
    border-top: 2px solid var(--bg-primary);

    .openDownloads {
      svg {
        fill: var(--text-primary);
      }
    }

    .openDownloads, .openEmoji, .sendBtn {
      height: 56px;
      @include flex;
      cursor: pointer;

      svg {
        fill: var(--text-action);
      }
    }

    .input {
      @include flex;
      flex: 1;
      z-index: 100;
      margin-top: 4px;
      margin-bottom: 12px;

      .timerRecording {
        @include flex(flex-start);
        gap: 12px;
        flex: 1;
        margin-bottom: 14px;

        .indicator {
          @include box-size(12px, 12px);
          background-color: var(--control-negative);
          border-radius: 50%;
          animation: pulsateIndicator 1.6s ease-out;
          animation-iteration-count: infinite;
        }

        .timer {
          color: var(--text-primary);
        }
      }

      .voicePreview {
        @include flex;
        @include box-size(100%, 100%);
        gap: 8px;

        .deleteIcon {
          cursor: pointer;

          svg {
            fill: var(--text-negative);
          }
        }
      }
    }
  }


}

@keyframes pulsateIndicator {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
}
