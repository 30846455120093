@import "../../../../../app/styles/index";

.primary {
  color: var(--text-primary) !important;
}

.textWrap {
  white-space: normal !important;
  overflow-wrap: break-word;
}

.wordBreak{
  word-break: break-word;
  white-space: pre-wrap!important;
  word-wrap: break-word;
  line-height: 16px !important;
  width: auto !important;
}

.edited {
  min-height: 32px;
  line-height: 32px !important;
  cursor: pointer;
}

.wrapper {
  color: var(--text-secondary);
  width: 100%;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;

  .input {
    width: 100%;
  }
}

.red{
  color: var(--text-negative) !important;
}

.fixed{
  color: var(--text-fixed) !important;
}

.green{
  color: var(--green) !important;
}

.inactive{
  color: var(--text-inactive) !important;
}

.showInput {
  padding-right: 24px !important;


  .input {
    @include flex(flex-start);
    @include box-size(100%, 32px);

    .icon {
      @include flex;
      @include box-size(32px, 32px);
      min-width: 32px;
      border-radius: 50%;
      background-color: var(--bg-primary);
      cursor: pointer;

      svg {
        fill: var(--text-action);
      }
    }
  }

  input {
    text-align: left;
    border-bottom: 1px solid var(--text-action);


  }
}


.active {
  color: var(--text-action) !important;
}

.textSelect{
  -moz-user-select: text;
  -webkit-user-select: text ;
}

.text {
  color: var(--text-primary) !important;
  width: 100%;

  .emojiWrapper {
    color: transparent !important;
    position: relative;
    pointer-events: none;

    .emoji {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.H1 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.H2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.H3B {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.H3R {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.H3S {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.H3M {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.H4S {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.H4M {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}


.H4R {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.Body16 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.Body14 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.caption1S {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.caption1M {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.caption2S {
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.caption2M {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

