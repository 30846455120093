@import "src/app/styles";

.wrapper {
  @include box-size(100%, 100%);
  @include flex;
  gap: 12px;

  .video {
    @include flex(center, flex-end);
    @include box-size(100%, 100%);
  }

  .controls {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    @include box-size(500px, calc(92px - 32px));
    background-color: rgb(0, 0, 0, 0.4);
    border-radius: 12px;
    padding: 16px;

    @include width-to(sm) {
      width: 400px;
    }
    svg {
      fill: var(--text-primary);
    }

    .top {
      @include flex(space-between);
      @include box-size(100%, 50%);

      .volume {
        @include flex;
        @include box-size(100px, 50%);
        gap: 8px;
      }

      .actions {
        @include flex(flex-end);
        @include box-size(auto, 50%);
        gap: 8px;

        .speed {
          color: var(--text-fixed);
          font-weight: 600;
          font-size: 16px;
        }
      }
    }

    .bottom {
      @include flex;
      @include box-size(100%, 50%);
      gap: 8px;
      color: var(--text-primary);
      .time {
        width: 44px;
      }

      .timeReverse {
        width: 50px;
      }

      .slider {
        margin-top: 11px;
        @include box-size(347, 100%);
        flex: 1;

      }
    }

  }
}

