@import "src/app/styles";

.wrapper {
  @include box-size(calc(100% - 24px), auto);
  @include flex-column(flex-start,flex-start);
  gap: 24px;
  padding: 16px 12px;

  @include width-to('sm') {
    @include box-size(calc(100% - 8px), auto);
    padding: 16px 4px;
  }
}