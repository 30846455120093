@import "src/app/styles";

.wrapper {
  @include flex-column(flex-start,flex-start);
  box-shadow: var(--box-shadow);
  border-radius: 12px;
  z-index: 100000;
  background-color: var(--bg-secondary);
  padding: 8px 0;

  .item {
    padding: 0 8px;
    //min-width: 200px;
    width: calc(100% - 16px);
    height: 40px;
    @include flex(flex-start);
    transition: background-color 0.5s;
    cursor: pointer;
    white-space: nowrap;

    svg {
      fill: var(--text-primary);
    }

    &:hover {
      background-color: var(--bg-primary);
    }

    .content {
      color: var(--text-primary);
      @include flex(flex-start);
      width: 86%;
      gap: 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &_red {
        color: var(--text-negative) !important;
      }

      .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}
