@import "src/app/styles";

.wrapper {
  @include flex-column;
  width: 100%;
  //min-width: 170px;
  max-width: 460px;
  border-radius: 12px;

  .description {
    flex: 1;
    margin-left: 8px;
    margin-top: 8px;
    width: 0;
    min-width: calc(100% - 8px);
    border-left: 2px solid var(--border-primary);

    .content {
      cursor: pointer;
      margin-left: 6px;
    }
  }

  .body {
    @include flex(flex-start);
    width: 100%;

    .deleted {
      margin: 0 8px;
      width: calc(100% - 16px);
    }
  }
}


