@import "src/app/styles";

.wrapper {
  @include flex-column;
  width: 100%;
  height: 100%;
  padding: 20px;


  .camera {
    position: relative;

    .border {
      border: 10px solid var(--bg-secondary);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 28px;
      transform: translate(-50%, -50%);
      z-index: 100;
    }

    .cover {
      width: 100%;
      height: 98%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .panel {
    margin-top: 32px;
    width: 100%;

    .btns {
      @include flex;
      gap: 12px;
    }
  }

}



