@import "src/app/styles";

.wrapper {
  @include box-size(100%, auto);
  @include flex-column(flex-start, flex-start);

  padding: 40px;
  color: red;

  @include width-to(min) {
    padding: 40px 12px;
    width: calc(100% - 24px);
  }

  .frame {
    border-radius: 12px;
    background-color: var(--white);
    pointer-events: none;
  }
}