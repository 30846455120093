@import "src/app/styles";

.wrapper {
  @include flex-column(flex-start, flex-start, 6);
  color: var(--text-primary);
  max-width: 290px;

  padding: 4px 8px;
  border-radius: 12px;
  -moz-user-select: text;
  -webkit-user-select: text;

  .item {
    max-width: 200px;
    @media (max-width: 740px) and (min-width: 680px) {
      max-width: 150px;
    }
  }
}


