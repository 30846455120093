.wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;

  .title {
    font-size: 14px;
    color: var(--text-primary);
    text-align: left;
  }

  .inputWrapper {
    width: calc(100% - 24px) !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 12px;
    //background-color: var(--bg-primary);
    border: 1px solid var(--border-secondary);
    transition: all var(--transition);
  }

  .focused {
    border: 1px solid var(--border-primary-pressed);
  }

  .textArea{
    color: var(--text-primary);
    width: 100%;
    //padding: 4px 0;
    //height: calc(100% - 8px);
    word-break: break-word;
    white-space: pre-line;
    resize: none;
    outline: none;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: inherit;
  }

  .input {
    display: flex;
    //align-items: center;
    width: 100%;
    height: 100%;
    font-size: 18px;
    color: var(--text-primary);
  }

  .clearIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      fill: var(--text-primary) !important;
    }
  }

  .prefixIcon {
    display: flex;
    margin-right: 12px;

    svg {
      fill: var(--text-secondary);
    }
  }

  .inputPrefix {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--text-primary);
  }

  .search {
    border: none;
    padding-left: 0;
    padding-right: 0;
    background-color: inherit;
    min-width: 100%;
    svg{
      fill: var(--text-primary);
    }
  }

  .error {
    border: 1px solid var(--text-negative);

  }

  .size-s {
    width: calc(200px - 24px);
    height: 48px;
  }

  .size-m {
    width: calc(264px - 24px);
    height: 56px;
  }

  .errorTitle {
    font-size: 12px;
    text-align: left;
    font-weight: 500;
    color: var(--text-negative);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--title-color);
  -webkit-box-shadow: 0 0 0 40rem var(--background-color) inset;
}

input {
  all: unset;
  caret-color: var(--text-action-pressed);
}


::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

::placeholder {
  color: var(--text-inactive);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

