@import "src/app/styles";

.wrapper {
  @include box-size(100%, 100%);
  @include flex-column();
  position: relative;
  .menu{
    position: fixed;
    top: 12px;
    left: 12px;
  }
}


