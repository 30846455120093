@import "src/app/styles";

.wrapper {
  @include flex;
  @include box-size(100%, 74px);
  min-height: 74px;
  gap: 16px;
  cursor: pointer;
  transition: background-color var(--transition);

  .body {
    @include box-size(calc(100% - 32px), 100%);
    @include flex(flex-start);
    padding: 0 16px;
    gap: 16px;

    .avatar {
      @include flex;
      position: relative;

      .icon{
        @include flex;
        @include box-size(18px, 18px);
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        right: -4px;
        z-index: 100;
        background-color: var(--bg-secondary);
        transition: background-color var(--transition);
        &_active {
          background-color: var(--bg-primary);
        }
      }
    }

    .content {
      @include flex-column;
      width: calc(100% - 68px);
      gap: 4px;

      .row {
        @include flex(space-between);
        width: 100%;

        .left {
          @include flex(flex-start);
          gap: 4px;
          max-width: 86%;

          .authorName{
            white-space: nowrap;
            color: var(--text-primary);
          }
        }

        .right {
          @include flex(flex-end);
          min-width: 10%;
          width: auto;

          .icon{
            margin-right: 6px;
          }
        }

        svg {
          fill: var(--text-action);
        }
      }

    }

  }

  &_active {
    background-color: var(--bg-primary);
  }
}
