@import "src/app/styles";

.wrapper {
  @include box-size(100%, auto);
  @include flex-column();
  gap: 8px;
  position: relative;

  .reactions {
    @include box-size(264px, auto);
    @include flex-column();
    position: absolute;
    top: -50px;
    left: 50%;
    background-color: var(--bg-secondary);
    box-shadow: var(--box-shadow);
    border-radius: 12px;
    transform: translateX(-50%);
    z-index: 20;

    .baseList {
      @include box-size(calc(100% - 16px), 44px);
      @include flex;
      padding: 0 8px;
      gap: 8px;

      .list {
        @include flex(flex-start);
        width: 80%;
        height: 100%;
        gap: 12px;
        flex: 1;
        overflow-x: auto;
      }

      .btn {
        @include box-size(24px, 24px);
        @include flex;
        background-color: var(--bg-primary);
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .allList {
      @include box-size(calc(100% - 16px), auto);
      @include flex(flex-start);
      flex-wrap: wrap;
      padding: 8px 8px;
      gap: 12px;
    }

  }

  .items {
    @include flex-column();
    color: var(--text-primary);
    width: 100%;
    background-color: var(--bg-secondary);
    box-shadow: var(--box-shadow);
    border-radius: 12px;
    padding: 12px 0;
    position: relative;

    .users{
      @include box-size(calc(100% - 24px), auto);
      max-height: 200px;
      padding: 12px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: var(--bg-primary);
      border-radius: 12px;
      overflow-y: auto;
    }

    .item {
      width: calc(100% - 32px);
      height: 40px;
      @include flex(flex-start);
      gap: 12px;
      cursor: pointer;
      padding: 0 16px;


      &:hover {
        background-color: var(--bg-primary);
      }

      svg {
        fill: var(--text-primary);
      }
    }
  }

}


