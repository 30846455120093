@import "src/app/styles/index";

.wrapper {
  @include flex;
  width: 100%;
  gap: 8px;
  cursor: pointer;

  .icon {
    @include flex;
    width: 37px;
  }

  .caption {
    width: calc(100% - 45px);
    @include flex-column;
  }

  svg {
    fill: var(--text-primary);
  }

}

