@import "src/app/styles";


.wrapper {
  @include flex(flex-start);
  gap: 12px;
  width: 100%;

  .avatar {
  }

  .caption{
    @include flex-column(flex-start, flex-start);
    width: 100%;

    .title{
      @include flex(flex-start);
      gap: 6px;
      max-width: 100%;
    }
    //gap: 4px;
  }
}

