@import "../../../../../app/styles/index";

.wrapper {
  @include flex-column(flex-start);
  @include box-size(100%, 100%);
  background-color: rgb(0, 0, 0, 0.8);
  gap: 12px;
  position: relative;
  min-height: 100vh;

  .closeIcon {
    @include flex;
    @include box-size(40px, 40px);
    border-radius: 50%;
    position: absolute;
    top: 12px;
    right: 12px;
    background-color: var(--white);
    cursor: pointer;
    z-index: 100;

    svg {
      fill: black;
    }
  }

  .swiperTop {
    @include flex;
    width: 100%;
    height: 100%;
    flex: 1;
    position: relative;

    .fullscreen {
      @include flex;
      @include box-size(40px, 40px);
      background-color: rgb(0, 0, 0, 0.4);
      border-radius: 50%;
      position: absolute;
      bottom: 20px;
      left: 50%;
      z-index: 100;
      transform: translateX(-50%);
      cursor: pointer;

      svg {
        fill: var(--white);
      }
    }

    .slideTop {
      @include flex;
      height: 100%;
      width: auto;
      position: relative;

    }

    svg {
      fill: var(--white);
    }

    .btnLeft {
      left: 0;
    }

    .btnRight {
      right: 0;
    }

    .btnLeft, .btnRight {
      @include flex;
      height: 100%;
      width: 50px;
      cursor: pointer;
      opacity: 0;
      transition: opacity 0.5s;
      position: absolute;
      top: 0;
      background-color: rgb(0, 0, 0, 0.8);
      border-radius: 12px;

      &:hover {
        opacity: 1;
      }
    }
  }

  .footer {
    @include flex-column;
    gap: 18px;
    width: 100%;
    position: relative;
    margin: 20px 0;

    &_hidden {
      display: none;
    }

    .card {
      position: absolute;
      max-width: 200px;
      bottom: 10px;
      left: 20px;

      @include width-to(md) {
        display: none;
      }
    }

    .swiperContainer {
      @include flex;
      //max-width: calc(70% + 30px);
      position: relative;
      max-width: 90%;

      .swiper {
        @include flex(flex-start);
        overflow-x: auto;
        width: 100%;
        scroll-behavior: smooth;
        gap: 6px;

        .slideBottom {
          @include flex;
          min-width: 56px;
          height: 80px;
          border-radius: 12px;
          overflow: hidden;
          opacity: 0.6;
          cursor: pointer;
          background-color: #7B57C8;

          &_active {
            opacity: 1;
          }
        }
      }
      svg {
        fill: var(--white);
      }
      .btnLeft {
        cursor: pointer;
        position: absolute;
        top: 40%;
        left: -26px;
        z-index: 100;
      }

      .btnRight {
        cursor: pointer;
        position: absolute;
        top: 40%;
        right: -26px;
        z-index: 100;
      }
    }

    .actions {
      @include box-size(auto, 40px);
      @include flex;
      padding: 0 25px;
      gap: 16px;
      border-radius: 24px;
      background: var(--white);

      .download{
        position: relative;

        .menu{
          @include flex-column(flex-start, flex-start);
          @include box-size(94px, auto);
          padding: 12px;
          gap: 12px;
          position: absolute;
          bottom: 40px;
          left: 20px;
          background-color: var(--white);
          border-radius: 12px;
        }
      }
      
    }

    .item {
      cursor: pointer;
      font-weight: 600;
    }

    svg {
      fill: black;
    }
  }


}




