@import "src/app/styles";

.wrapper {
  @include flex(flex-start);
  width: 100%;
  //min-width: 170px;
  max-width: 400px;
  border-radius: 12px;
  padding-top: 8px;

  .content {
    @include flex-column;

    .description {
      margin: 0 8px;
      width: calc(100% - 16px);
    }

    .body {
      @include flex(flex-start);
      width: 100%;

      .deleted {
        margin: 0 8px;
        width: calc(100% - 16px);
      }
    }
  }

}


