@import "src/app/styles";

.wrapper {
  @include box-size(100%, 100%);
  @include flex;
  background-color: inherit;
  border-radius: 12px;
  position: relative;
  //overflow: hidden;
  flex-grow: 1;
  min-width: 37px;
min-height: 37px;


  .savingFile {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .remove {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .error {
    display: none !important;
  }

  .loading {
    @include flex;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--bg-quaternary);
    width: 100%;
    height: 100%;
    z-index: 100;
    border-radius: 12px;
  }

  .img {
    @include box-size(100%, 100%);
    @include flex;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
  }

  .contain {
    object-fit: contain !important;
  }

  .imgHidden {
    display: none;
  }
}

