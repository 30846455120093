@import "src/app/styles/index";

.wrapper {
  @include flex-column();
  @include box-size(100%, 60px);
  background-color: var(--bg-secondary);
  border-top: 1px solid var(--border-secondary);
  position: relative;
  //overflow: hidden;

  .container {
    @include flex(space-between);
    gap: 12px;
    @include box-size(calc(100% - 28px), 60px);
    padding: 0 14px;
    max-width: 1440px;

    .left, .right {
      @include flex;
      gap: 20px;

      .time {
        @include flex
      }

      .item {
        cursor: pointer;
      }

      svg {
        fill: var(--control-primary);
      }
    }

    .left {
      @include flex(flex-start);
      gap: 10px;
      flex: 1;
      width: 0;

      .controls {
        @include flex(flex-start);
        gap: 14px;
      }

      .descriptions {
        cursor: pointer;
        width: 0;
        flex: 1;
      }
    }

    .right {
    }
  }

  .slider {
    @include box-size(100%, 7px);
    z-index: 100;
    position: absolute;
    left: 0;
  }

  .timing{
    @include flex;
    width: 90px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-secondary);
  }

}

