@import "src/app/styles";

.wrapper {
  position: fixed;
  top: 12px;
  right: 12px;
  @include flex-column(flex-end, flex-end);
  gap: 8px;
  z-index: 12323232322100;

  .item {
    @include flex-column(flex-start, flex-start);
    gap: 12px;
    background-color: var(--bg-secondary);
    padding: 12px 24px 12px 12px;
    border-radius: 8px 0 8px 8px;
    box-shadow: var(--box-shadow);
    position: relative;
    max-width: 450px;

    &_close{
      position: absolute;
      top: 2px;
      right: 2px;
    }
  }
}