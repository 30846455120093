@import "src/app/styles";

.wrapper {
  @include flex(space-between);
  min-width: 33px;
  height: 20px;
  gap: 4px;
  background-color: var(--bg-03);
  padding: 0 2px;
  border-radius: var(--border-radius);
  cursor: pointer;

  .avatars {
    @include flex(flex-start);

    .avatar {
      margin-right: -5px;
    }
  }

  .count {
    @include flex;
    color: var(--text-0);
    font-weight: 600;
    font-size: 14px;
  }
}
