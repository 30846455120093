@import "src/app/styles/index";

.wrapper {
  @include flex;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
  max-height: 220px;
}

.focused {
  border: 1px solid var(--border-primary-pressed);
}

.input {
  caret-color: var(--text-primary) !important;
  word-break: break-word;
  white-space: pre-line;
  resize: none;
  outline: none;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: transparent !important;

}

.visibleBorder {
  border-radius: 12px;
  border: 1px solid var(--border-secondary);
}

.input::selection {
  background: rgb(66, 170, 255, 0.3);
  opacity: 0.5;
}

.emoji {
  align-self: flex-end;
  margin-bottom: 4px;
}

.disabled {
  pointer-events: none;
}

.H3M {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.H4M {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}