@import "src/app/styles";

.wrapper {
  @include box-size(100%, 100%);
  @include flex-column(flex-start, flex-start);
  gap: 6px;
  flex-wrap: wrap;
  min-width: 100%;
  //border-radius: 12px;
  //overflow: hidden;

  .item{
    @include flex(flex-start);
    width: 100%;
  }
}

