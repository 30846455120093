:root[data-theme="dark"] {
  --text-primary: #FFFFFF;
  --text-secondary: #9293AE;
  --text-inactive: #717394;
  --text-fixed: #FFFFFF;
  --text-action: #7B57C8;
  --text-action-pressed: #6F4BBC;
  --text-positive: #10C44C;
  --text-negative: #FF4B4B;
  --text-negative-pressed: #E74343;
  //------------------------------------
  --bg-html: #141c27;
  --bg-primary: #46384C;
  --bg-secondary: #2E2731;
  --bg-tertiary: #717394;
  --bg-quaternary: #332F6B;
  --bg-dialogue: #221D24;
  --bg-dimming: rgba(83, 60, 103, 0.5);
  --bg-chat-primary:#46384C;
  --bg-chat-secondary:#35243D;
  //------------------------------------
  --control-primary: #7B57C8;
  --control-primary-pressed: #6F4BBC;
  --control-secondary: #DDE4EF;
  --control-secondary-disabled: #717394;
  --control-tertiary: #717394;
  --control-tertiary-active: #7B57C8;
  --control-negative: #FF4B4B;
  --control-negative-pressed: #E74343;
  --control-scroll: #55569F;
  --control-scroll-bg: #332F6B;

  //------------------------------------
  --border-primary: #7B57C8;
  --border-primary-pressed: #6F4BBC;
  --border-secondary: #0E1621;
  --border-negative: #FF4B4B;
  --border-negative-pressed: #E74343;
  //------------------------------------
  --box-shadow: 0 0 6px 0 #717394;
  --box-shadow-active: 0 0 3px 0 #717394;
  //------------------------------------
  input {
    color-scheme: dark;
  }
}