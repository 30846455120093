@import "src/app/styles/index";

.wrapper {
  @include box-size(auto, 100%);
  @include flex;
  gap: 30px;
  flex-wrap: wrap;
  max-height: 90vh;
  //max-width: 80vw;
  overflow-y: auto;

  .form {
    @include flex-column();
    margin: 60px 10px;
    gap: 20px;
    width: 400px;

    @include width-to('sm') {
      margin: 10px 10px;
    }

    .input {
      @include flex;
      @include box-size(110px, 100%);
      margin: 0 auto;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 7px;

      &::placeholder {
        color: var(--text-inactive) !important;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 7px;
      }
    }
  }

  .img {
    @include flex;
    margin: 30px 30px 30px 0;

    svg {
      @include box-size(80%, 90%)

    }
  }
}