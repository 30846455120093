@import "src/app/styles";

.mask {
  @include flex;
  @include box-size(100%, 100%);
  @include position;
  //background: rgba(0, 0, 0, 0.5);
  background-color: var(--bg-dimming);
  z-index: 100000;
  overflow: hidden;

  &_top {
    align-items: flex-start !important;
  }

  .modal {
    @include flex-column(space-between);
    border-radius: 20px;
    background-color: var(--bg-secondary);
    z-index: 1000;
    position: relative;
    max-height: calc(100vh - 80px);
    margin: 40px 0;

    @include height-to(sm) {
      max-height: calc(100vh - 24px);
      margin: 12px 0;
    }

    .content {
      @include flex;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      overflow-y: auto;

      &_full {
        max-height: 100% !important;
      }
    }

    .closeIcon {
      @include flex;
      @include box-size(40px, 40px);
      position: absolute;
      top: -28px;
      right: -48px;
      z-index: 100;
      cursor: pointer;
      border-radius: 50%;
      background-color: var(--bg-secondary);

      svg {
        fill: var(--text-primary);
      }

      @include width-to('sm') {
        top: 12px !important;
        right: 12px !important;
        border: 2px solid var(--text-secondary);
      }

      @include height-to('sm') {
        top: 12px !important;
        right: 12px !important;
        border: 2px solid var(--text-secondary);
      }

    }
  }

  .full {
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    border-radius: 0 !important;
    background-color: transparent !important;
    margin: 0 !important;
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
}

.loading {
}

.error {
}
