@import "src/app/styles";


.wrapper {
  @include flex;
  color: #fafafa;
  border-radius: 50%;
  white-space: nowrap;
  //overflow: hidden;
  position: relative;
  box-shadow: var(--box-shadow);


  .status {
    @include flex;
    @include box-size(14px, 14px);
    max-width: 24px;
    max-height: 24px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: -2px;
    z-index: 100;
    border: 3px solid var(--bg-secondary);
  }

  .loading {
    @include box-size(100%, 100%);
    @include flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  svg {
    fill: #fafafa;
  }
}

.avatarBc {
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

}

