@import "src/app/styles";

.wrapper {
  overflow: hidden;
  @include flex;
  min-width: 10px;
  background-color: var(--control-primary);
  color: var(--text-fixed);
  border-radius: 12px;
  font-weight: 500;
  font-size: 12px;
  padding: 0 4px;

}

.negative {
  background-color: var(--control-negative);
}

.quaternary {
  background-color: var(--bg-quaternary);
}
