@import "src/app/styles";

.wrapper {
  @include box-size(auto, auto);
  @include flex(center, flex-end);
  gap: 12px;
  max-width: 90%;


  .avatar {
    position: relative;
  
    .img {
      position: absolute;
      bottom: 0;
      cursor: pointer;
    }
  }

  .noAvatar {

  }

  .content {
    @include box-size(100%, auto);
    flex: 1;
    position: relative;
    background-color: var(--bg-secondary);
    border-radius: 12px;

    .active{
      background: #BDB1D7 !important;
    }

    .isMy {
      background-color: var(--bg-chat-primary);
      border-radius: 12px 12px 0 12px !important;
      overflow: hidden;
      border: 2px solid var(--bg-chat-primary);
    }

    .another{
      border-radius: 12px 12px 12px 0 !important;
      background-color: var(--bg-chat-secondary);
      overflow: hidden;
      border: 2px solid var(--bg-chat-secondary);
    }

    .my_last {
      border-radius: 12px 12px 0 12px !important;
    }

    .another_last {
      border-radius: 12px 12px 12px 0 !important;
    }

    .authorName {
      cursor: pointer;
      margin: 4px 8px 0 8px;
      color: var(--text-action);
      //position: absolute;
      //top: -24px;
      //left: 0;
    }

  }
}


