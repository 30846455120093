@import "src/app/styles";

.wrapper {
  @include box-size(calc(416px - 48px), 100%);
  @include flex-column;
  gap: 24px;
  padding: 32px 24px;
  position: relative;

  .testInput {
    position: absolute;
    top: 120px;
    left: 20px;
  }

  .sizeLimit {
    @include box-size(100%, auto);
    @include flex-column(space-between);
    gap: 12px;

    .size {
      @include box-size(100%, 48px);
      @include flex(space-between);
      width: 100%;
    }

    .slider {
      width: 100%;
      height: 20px;

    }
  }

  .categories {
    @include flex-column(flex-start);
    width: 100%;
    gap: 8px;
    overflow-y: auto;
    font-weight: 500;

    .item {
      @include box-size(100%, 48px);
      @include flex(space-between);

      .description {

      }

      .icon {
        cursor: pointer;
      }
    }
  }

  .clearAll {
    width: 100%;
  }

  .info {
    overflow-y: auto;
  }

}


