@import "src/app/styles";


.wrapper {
  @include flex(space-between);
  @include box-size(calc(100% - 44px), auto);
  padding: 16px 22px;
  position: relative;
  background: var(--bg-secondary);

  .btns {
    @include flex;
    gap: 24px;

    .btn {
      cursor: pointer;
    }
  }

  .text {
    margin: 12px;
    width: 0;
    flex: 1;
  }

  .cancel {
    cursor: pointer;
  }

}