@import "src/app/styles/index";

.wrapper {
  @include box-size(416px, 100%);
  @include flex-column(flex-start, center);
  max-height: calc(100vh - 22px);
  overflow-y: auto;

  @include height-from(sm) {
    max-height: calc(100vh - 80px);
  }

  .header {
    @include flex-column();
    width: calc(100% - 48px);
    margin: 32px 24px 0px 24px;
    color: var(--text-primary);

    .selected{
      @include flex(flex-start);
      @include box-size(100%, auto);
      @include scrollBarVertical;
      max-height: 120px;
      margin-top: 12px;
      margin-bottom: 12px;
      flex: 1 0 auto;
      flex-wrap: wrap;
      gap: 6px;
      overflow-y: auto;

      @include height-to('sm'){
        max-height: 50px;
      }

      .item{
        @include flex;
        gap: 6px;
        padding: 8px 14px;
        background-color: var(--control-primary);
        border-radius: 12px;
        color: var(--text-fixed);

        .icon{
          @include flex;
          cursor: pointer;
          margin-top: 2px;
          svg{
            fill: var(--text-fixed);
          }
        }
      }
    }

    .search {
      margin-top: 12px;
      //margin: 16px 0;
      width: 100%;
    }
  }



  .list {
    @include scrollBarVertical;
    @include flex-column(flex-start);
    gap: 12px;
    width: calc(100% - 24px);
    padding: 12px;
    //margin-left: 24px;
    overflow-y: auto;
    //min-height: 100%;
  }

  .footer {
    @include flex-column;
    gap: 12px;
    width: 90%;
    margin-top: 16px;
    margin-bottom: 28px;


    svg {
      fill: var(--text-action) !important;
    }
  }
}