@import "src/app/styles/index";

.wrapper {
  @include box-size(100%, 60px);
  @include flex;
  gap: 6px;
  position: relative;


  .controls {

    button {
      svg {
        fill: var(--text-fixed);
      }
    }
  }


  .time {
    @include flex(flex-start);
    //gap: 6px;
    color: var(--text-primary);
    font-size: 12px;
    position: absolute;
    bottom: 0;
    left: 40px;
  }


  .waveform {
    @include box-size(100%, 100%);
    @include flex;
    overflow: hidden;

    .cover {
      position: absolute;
      @include box-size(80%, 100%);
      z-index: 10;
    }
  }


}
