@import "src/app/styles";

.wrapper {
  @include box-size(100%, 100%);
  @include flex-column(flex-start);
  border-radius: var(--border-radius);


  .search {
    @include box-size(calc(100% - 32px), 48px);
    @include flex(space-between);
    padding: 0 16px;
    margin-bottom: 12px;
  }

  .tabs {
    @include box-size(calc(100% - 16px), 48px);
    @include flex(flex-start);
    padding-left: 16px;
  }


  .list {
    @include flex-column(flex-start);
    width: 100%;
    height: calc(100% - 100px);
    overflow-y: auto;
    padding: 8px 0;

    .item {
      @include flex;
      @include box-size(calc(100% - 32px), 74px);
      min-height: 74px;
      padding:0 16px;
      gap: 16px;
      cursor: pointer;
      transition: background-color var(--transition);

      .body {
        @include box-size(100%, 100%);
        @include flex(space-between);
        gap: 16px;

        .card {
          width: 0;
          flex: 1;
        }

        .icons {
          @include flex(flex-end);
          flex-shrink: 1;

          svg {
            fill: var(--text-primary);
          }
        }
      }

      &_active {
        background-color: var(--bg-primary);
      }
    }
  }


}
